<template>   
    <div class="secondPanel">
        <div class="panelTitle">
            <img :src="`${publicPath}img/customicons/Eye.png`" style="height: 28px;"/>
            <div class="">{{ draftIndex !== null ? $t('addRecord.edit-draft') : $t('addRecord.add-a-record') }}</div>
            <div class="buttons" @click="closePanelClickHandler">
                <img :src="`${publicPath}img/customicons/close.png`" />
            </div>
        </div>
        
        <div class="sectionDivider" style="margin-top: 5px;"></div>
        
        <div class="panelContents" v-if="mapTooltipShown" :class="{reducedPanelContents:isDesktop}">
            <div class="trackTitle">{{ $t('addRecord.set-location') }}</div>
            <div class="fieldDescription" v-html="$t('addRecord.tooltip')"></div>
            <button class="defaultButton" style="margin: 20px 0px 0px" @click="locationHasBeenSet">
                {{ $t('general.continue') }}
            </button>
        </div>

        <!-- <div class="panelContents" style="text-align: center;" v-if="mapTooltipShown">
            <div class="trackTitle">{{ $t('addRecord.set-location') }}</div>
            <div class="fieldDescription" style="margin: 20px 50px;" v-html="$t('addRecord.tooltip')"></div>
            <button class="defaultButton" style="margin: 20px 0px 0px; width: max-content; min-width: 250px;" @click="locationHasBeenSet">
                {{ $t('general.continue') }}
            </button>
        </div> -->

        <div class="panelContents" id="addTrackContents" v-if="!mapTooltipShown && !successShown && !errorShown && validationErrors.length == 0">            
            <div class="trackTitle">{{ $t('addRecord.select-a-category') }}</div>
            <div class="typesContainer">
                <div class="typeButtonContainer"
                    v-for="category in categories" 
                    :key="category.id" 
                    @click="selectCategory(1,category)"> 
                    <div class="typeButton" :style="{ 'background-image': 'url(/img/category_icons/'+category.id+'.png)'}">
                        <img class="categoryIcon" :src="`${publicPath}img/customicons/CheckCircleWhite.png`" v-if="category1 == category" />
                    </div>
                    <div>{{ $i18n.locale=='bu' ? category.name_bir : category.name}}</div>
                </div>
            </div>   


            <div class="subcategory2" v-if="categoryLevel >= 2 && categories2 && categories2.length > 0">
                <div class="sectionDivider"></div>
                <div class="trackTitle">{{ $t('addRecord.choose-sub-categories') }}</div>
                <div class="fieldDescription">{{ $i18n.locale=='bu' ? categories2[0].caption_bir : categories2[0].caption}}</div>
                <v-select
                    v-model="category2" :items="categories2"
                    :item-text= "$i18n.locale=='bu' ? 'name_bir' : 'name'"    
                    :return-object="true"
                    outlined
                    v-on:change="selectCategory(2,category2)"
                ></v-select>
            </div>

            <div  class="subcategory3" v-if="categoryLevel >= 3 && categories3 && categories3.length > 0">
                <div class="sectionDivider" style="margin: 0px 0px 10px;border-bottom-style: dashed;"></div>
                <div class="fieldDescription">{{ $i18n.locale=='bu' ? categories3[0].caption_bir : categories3[0].caption}}</div>
                    <v-select
                        v-model="category3" :items="categories3"
                        :item-text= "$i18n.locale=='bu' ? 'name_bir' : 'name'"   
                        :return-object="true"
                        outlined
                        v-on:change="selectCategory(3, category3)"
                    ></v-select>
            </div>


            <div  class="subcategory4" v-if="categoryLevel >= 4 && categories4 && categories4.length > 0">
                <div class="sectionDivider" style="margin: 0px 0px 10px;border-bottom-style: dashed;"></div>
                <div class="fieldDescription">{{ $i18n.locale=='bu' ? categories4[0].caption_bir : categories4[0].caption}}</div>
                    <v-select
                        v-model="category4" :items="categories4"
                        :item-text= "$i18n.locale=='bu' ? 'name_bir' : 'name'"   
                        :return-object="true"
                        outlined
                        v-on:change="selectCategory(4, category4)"
                    ></v-select>
            </div>
            
            <div  class="subcategory5" v-if="categoryLevel >= 5 && categories5 && categories5.length > 0">
                <div class="sectionDivider" style="margin: 0px 0px 10px;border-bottom-style: dashed;"></div>
                <div class="fieldDescription">{{ $i18n.locale=='bu' ? categories5[0].caption_bir : categories5[0].caption}}</div>
                    <v-select
                        v-model="category5" :items="categories5"
                        :item-text= "$i18n.locale=='bu' ? 'name_bir' : 'name'"   
                        :return-object="true"
                        outlined
                        v-on:change="selectCategory(5, category5)"
                    ></v-select>
            </div>
            
            <div  class="subcategory6" v-if="categoryLevel >= 6 && categories6 && categories6.length > 0">
                <div class="sectionDivider" style="margin: 0px 0px 10px;border-bottom-style: dashed;"></div>
                <div class="fieldDescription">{{ $i18n.locale=='bu' ? categories6[0].caption_bir : categories6[0].caption}}</div>
                    <v-select
                        v-model="category6" :items="categories6"
                        :item-text= "$i18n.locale=='bu' ? 'name_bir' : 'name'"   
                        :return-object="true"
                        outlined
                        v-on:change="selectCategory(6, category6)"
                    ></v-select>
            </div>


            <div class="sectionDivider" ></div>            

            <div class="trackTitle scaleImpactField">{{ $t('trackDetails.impact-on-nature') }}</div>
            <div class="fieldDescription" style="margin-bottom: 30px;">{{ $t('addRecord.move-scale-to-select') }}</div>
            <div class="trackDetailsChart" style="margin-bottom: -20px; margin-left: 15px; margin-right: 15px;"></div>
            <v-slider
                v-model="scale"
                min="1"
                max="5"
                thumb-color="#FFC352"
                @change="scaleImpactChanged" 
                style="margin-bottom: -22px;"
            ></v-slider>
            <div class="scaleTicks" style="padding: 0px 15px;">
                <div class="scaleTicksMarks" />
                <div class="scaleTicksMarks" />
                <div class="scaleTicksMarks" />
                <div class="scaleTicksMarks" style="border-right: solid 1px #CEB6B2;"/>
            </div>
            <div class="scaleLegend" style="padding: 0px 15px;">
                <div class="scaleLegendItem">{{ $t('scale.negative') }}</div>
                <div class="scaleLegendItem" style="text-align: center;">{{ $t('scale.neutral') }}</div>
                <div class="scaleLegendItem" style="text-align: right;">{{ $t('scale.positive') }}</div>
            </div>

            <div class="sectionDivider"></div>

            <div class="trackTitle datePickerField">{{ $t('addRecord.date') }}</div>

            <input class="defaultInput" type="datetime-local"  v-model="date" style="width: min-content; width: 250px;" @change="datePickerChanged"/>
            
            <div class="sectionDivider" ></div>


            <div class="trackTitle descriptionField">{{ $t('addRecord.add-a-description') }}</div>
            <div class="fieldDescription">{{ $t('addRecord.description-text') }}</div>
            <div class="groupContainer" style="text-align: left; padding: 10px; min-height: 100px;">
                <textarea v-model="description" name="description" id="" style="width: 100%; height: 150px;"></textarea>
            </div>



            <div class="">
                <div class="sectionDivider"></div>
                <div class="trackTitle">{{ $t('addRecord.region') }}</div>
                <div class="fieldDescription">{{ $t('addRecord.region-text') }}</div>
                <v-select
                    v-model="region_selected" :items="regions"
                    :item-text= "$i18n.locale=='bu' ? 'name' : 'extra_name'"     
                    :return-object="true"
                    outlined
                    v-on:change="regionChanged()"
                ></v-select>
            </div>


            <div class="sectionDivider"></div>


            <div class="trackTitle photoPicker">{{ $t('addRecord.add-a-picture') }}</div>
            <photo-capture @change="onPhotoChanged">
                <template v-slot:preview="props">
                <div class="emptyPreview2" v-if="!props.imageData">
                    <img :src="`${publicPath}img/customicons/photo_target.png`" alt="" style="width: 30px;"/>
                </div>
                <img class="previewImage" :src="props.imageData" alt="" v-if="props.imageData" />
                <div :class="{ photoButtonsContainer:true, photoButtonsContainerDesktop:isDesktop}">
                    <div v-if="!isDesktop" class="baseButton cameraButton" @click="props.openCamera">
                        <img :src="`${publicPath}img/customicons/Camera.png`" width="30">
                    </div>
                    <div></div>
                    <div class="baseButton cameraRollButton" @click="props.openFile">
                        <img :src="`${publicPath}img/customicons/Image.png`" width="30">
                    </div>
                </div>
                </template>                
            </photo-capture>


            <div class="sectionDivider" style="margin: 30px 0px; border-bottom-style: dashed;"></div>

            <div class="radioContainer ownPhotoField">
                    <div class="radioContainerCell1">
                        <div class="round">
                            <input type="checkbox" id="checkbox_photo_is_mine" v-model="photo_is_mine" @change="ownPhotoChanged"/>
                            <label for="checkbox_photo_is_mine"></label>
                        </div>
                    </div>
                    <div class="radioContainerCell2">
                        {{ $t('addRecord.own-image') }}
                    </div>
            </div>
        
            <div class="sectionDivider" style="margin: 30px 0px;"></div>

            <div class="trackTitle disclaimerField" style="margin-bottom: 20px">{{ $t('addRecord.disclaimer') }}</div>
            <div class="fieldDescription" style="margin-bottom: 20px" v-html="$t('addRecord.disclaimer-text0')">
            </div>
            <div class="radioContainer">
                    <div class="radioContainerCell1">
                        <div class="round">
                            <input type="checkbox" id="checkbox_disclaimer" v-model="disclaimer"/>
                            <label for="checkbox_disclaimer"></label>
                        </div>
                    </div>
                    <div class="radioContainerCell2">
                        {{ $t('addRecord.disclaimer-text1') }}
                    </div>
            </div>

            
            <button class="defaultButton" style="margin: 50px 0px 0px" @click="submitTrackHandler">
                <span v-if="!saving">{{ $t('addRecord.save-record') }}</span>
                <img v-if="saving" :src="`${publicPath}img/assets/loading.gif`" style="width: 30px; margin-bottom: -10px;"/>
            </button>
        </div>

        <!-- SUCCESS MESSAGE -->
        <div class="successMessage" v-if="successShown">
            <div class="trackTitle">{{ $t('addRecord.success-title') }}</div>
            <div v-html="$t('addRecord.success-text')"></div>
            <button class="defaultButton" @click="closePanelClickHandler">{{ $t('general.continue') }}</button>
        </div>

        <!-- ERROR MESSAGE -->
        <div class="successMessage" v-if="errorShown">
            <div class="trackTitle">{{ $t('addRecord.error-text') }}</div>
            <div>{{ $t('addRecord.error-text0') }}</div>
            <button class="defaultButton" @click="closePanelClickHandler">{{ $t('addRecord.save-as-draft') }}</button>
        </div>

        <!-- VALIDATION ERROR MESSAGE -->
        <div class="successMessage" v-if="validationErrors.length > 0">
            <div class="trackTitle">{{ $t('addRecord.validation-error-title') }}</div>
            <div style="text-align: left; padding: 0px 20px;">
                <ul>
                    <li v-for="error in validationErrors" :key="error">
                        {{error}}
                    </li>
                </ul>
            </div>
            <button class="defaultButton" @click="validationErrors = []">{{ $t('general.continue') }}</button>
        </div>

    </div> 
</template>


<script>
import { mapState } from 'vuex';
import PhotoCapture from './PhotoCapture.vue'
import gsap from 'gsap'


export default{
    props: {
        getMapCenter:Function
    },
    components: {
        PhotoCapture
    },
    data(){
        let now = new Date();
        let formattedDate = now.getFullYear()
            +"-"+(now.getMonth()+1).toString().padStart(2, '0') // Make sure the values have the right number of digits, otherwise vuetify's datepicker seems to fail
            +"-"+now.getDate().toString().padStart(2, '0')
            +"T"
            +now.getHours().toString().padStart(2, '0')
            +":"+now.getMinutes().toString().padStart(2, '0')
            ;
        return {
            publicPath: process.env.BASE_URL, 
            draftIndex: null,
            categoryLevel: 0,
            category1: null,
            category2: null,
            category3: null,
            category4: null,
            category5: null,
            category6: null,
            region_selected: null,
            tags: [], 
            scale: 3, 
            date: formattedDate,
            photo_is_mine: false,
            disclaimer: false, 
            description: "", 
            successShown: false, 
            errorShown: false, 
            mapTooltipShown: true,

            panelShown: false,
            photoFile: '', 
            saving: false,


            panelContents: null, 

            validationErrors: []
        }
    },
    mounted(){
        this.panelContents = document.querySelector( "#addTrackPanel .panelContents" );
    },
    methods: {
        scrollToElement(el) {
            let parentEl = document.getElementById("addTrackContents");
            console.log( parentEl, el )
            this.scrollParentToChild( parentEl, el );
            //if (el) el.scrollIntoView({ behavior: "smooth" });
        },
        regionChanged(){
            console.log( "region changed")
            setTimeout( ()=>{
                let el = document.querySelector(".photoPicker");
                if( el ){
                    this.scrollToElement( el );
                    //el.scrollIntoView( {behavior:"smooth"} );
                }
            }, 300 );
        },
        scaleImpactChanged(){
            let el = document.querySelector(".datePickerField");
            if( el ) {
                this.scrollToElement( el );
                //el.scrollIntoView( {behavior:"smooth"} );
            }
        },
        datePickerChanged(){
            let el = document.querySelector(".descriptionField");
            if( el ) {
                this.scrollToElement( el );
                //el.scrollIntoView( {behavior:"smooth"} );
            }
        },
        ownPhotoChanged(){
            let el = document.querySelector(".disclaimerField");
            if( el ) {
                this.scrollToElement( el );
                //el.scrollIntoView( {behavior:"smooth"} );
            }
        },

        scrollParentToChild(parent, child) {

            // Where is the parent on page
            var parentRect = parent.getBoundingClientRect();
            // What can you see?
            var parentViewableArea = {
                height: parent.clientHeight,
                width: parent.clientWidth
            };

            // Where is the child
            var childRect = child.getBoundingClientRect();
            // Is the child viewable?
            var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

            // if you can't see the child try to scroll parent
            //if (!isViewable) {
            //if (true) {
                    // Should we scroll using top or bottom? Find the smaller ABS adjustment
                    const scrollTop = childRect.top - parentRect.top;
                    const scrollBot = childRect.bottom - parentRect.bottom;
                    //if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                        // we're near the top of the list
                        //parent.scrollTop += scrollTop;
                        let target = parent.scrollTop + scrollTop;
                        gsap.to( parent, {scrollTop: target, duration: 0.4});
                    /* } else {
                        // we're near the bottom of the list
                        //parent.scrollTop += scrollBot;
                        let target = parent.scrollTop + scrollTop;
                        gsap.to( parent, {scrollTop: target, duration: 1});
                    } */
            //}

        },
        
        resetForm(){
            let now = new Date();
            let formattedDate = now.getFullYear()
            +"-"+(now.getMonth()+1).toString().padStart(2, '0') // Make sure the values have the right number of digits, otherwise vuetify's datepicker seems to fail
            +"-"+now.getDate().toString().padStart(2, '0')
            +"T"
            +now.getHours().toString().padStart(2, '0')
            +":"+now.getMinutes().toString().padStart(2, '0')
            ;
            this.draftIndex = null;
            this.categoryLevel = 0;
            this.category1 = null;
            this.category2 = null;
            this.category3 = null;
            this.category4 = null;
            this.category5 = null;
            this.category6 = null;
            this.region_selected = null;
            this.tags = []; 
            this.scale = 3; 
            this.date = formattedDate;
            this.photo_is_mine = false;
            this.photoFile = '';
            this.disclaimer = false; 
            this.description = ""; 
            this.successShown = false; 
            this.errorShown = false;
            this.panelShown = false;

            this.mapTooltipShown = true;
            
            this.saving = false;

            this.validationErrors = [];
            setTimeout( ()=>{
                this.panelContents.scrollTop = 0;
            }, 200);
        },
        openPanelHandler(){
            this.panelShown = true;
            this.mapTooltipShown = true;
            if( !this.regions || this.regions.length == 0 ){                
                this.$store.dispatch('fetchRegions', {} );
            }
        },
        locationHasBeenSet(){
            this.mapTooltipShown = false;
            this.$emit("locationIsSet");

        },
        closePanelClickHandler(){
            console.log( "closePanel()");
            this.panelShown = false;
            this.$emit("closeClicked");
        },        
        onPhotoChanged (file, data) {
            console.log('New Photo selected!')
            if (file) {
                console.log('Photo loaded.', file, data );
                this.photoFile = file;

                let el = document.querySelector(".ownPhotoField");
                if( el ) {
                    this.scrollToElement( el );
                    //el.scrollIntoView( {behavior:"smooth"} );
                }
                //this.image = image
            } else {
                console.log('FileReader API not supported: use the <form> event')
            }
        },
        
        selectCategory(level, item, skipScroll){
            console.log( "selectCategory: ", level, item );
            this["category"+level] = item;
            this.categoryLevel = level+1;
            this.resetSubcategories(level);
            this.$store.dispatch('fetchCategories', {level:this.categoryLevel, parentId:item.id} );
            if( level < 7 ){
                setTimeout( ()=>{
                    let el = document.querySelector(".subcategory"+(level+1));
                    if( el ){
                        this.scrollToElement( el );
                        //el.scrollIntoView( {behavior:"smooth"} );
                    }else{
                        el = document.querySelector(".scaleImpactField");
                        this.scrollToElement( el );
                        //el.scrollIntoView( {behavior:"smooth"} );                        
                    }
                }, 100);
            }

        }, 
        resetSubcategories( parentIndex ){
            for (let i = parentIndex+1; i <= 6; i++) {
                this["category"+i] = null;
            }
        },

        submitTrackHandler(){
            if( this.saving ){
                console.log( "Will not proceed with saving as there is already a saving process running");
                return;
            }

            let formIsValidated = this.validateForm();
            if( formIsValidated ){
                this.saveTrack();
                //alert( "will save track" );
            }
        },
        validateForm(){
            let errors = [];
            if( !this.category1 )
                errors.push( this.$t('validation.error0'));
            if( !this.category2 )
                errors.push( this.$t('validation.error1'));
            if( !this.region_selected )
                errors.push( this.$t('validation.error2'));
            if( !this.scale )
                errors.push( this.$t('validation.error3'));
            if( !this.date )
                errors.push( this.$t('validation.error4'));
            if( !this.disclaimer )
                errors.push( this.$t('validation.error5'));
            
            this.validationErrors = errors;
            if( errors.length > 0 )
                return false;
            else
                return true;
        },
        saveTrack(){
            this.saving = true;
            //const map = global.map;
            const position = this.getMapCenter();
            let formData = {
                category: this.category1 ? this.category1.id : null,
                category2: this.category2 ? this.category2.id : null,
                category3: this.category3 ? this.category3.id : null,
                category4: this.category4 ? this.category4.id : null,
                category5: this.category5 ? this.category5.id : null,
                category6: this.category6 ? this.category6.id : null,
                region_selected: this.region_selected ? this.region_selected.id : null,
                tags: this.tags,
                geo_location: {
                    type: "Point",
                    coordinates: [position.lng, position.lat]
                    //coordinates: [94.7020423,16.5503911]
                },
                scale: 6-this.scale,
                date: this.date, //"2021-07-16",
                photo_is_mine: this.photo_is_mine,
                description: this.description
            }
            //this.successShown = true;
            this.$store.dispatch('saveTrackData', { formData:formData, 
                // Success callback
                successCallback: (result)=>{
                    console.log( "[AddTrack] Success saving to DB", result );
                    this.uploadPhoto( result.id );
                    this.$store.dispatch('deleteDraftTrack', { draftIndex:this.draftIndex } );
                }, 
                // Error callback
                errorCallback: (error)=>{
                    console.log( "[AddTrack] Error saving to DB", error.toJSON() );
                    this.errorShown = true;
                    // Patch formData with categories full objects
                    formData.category_detail = this.category1 ? { id: this.category1.id, name: this.category1.name } : null;
                    formData.category = this.category1 ? this.category1.id : null;
                    formData.category1 = this.category1 ? { id: this.category1.id, name: this.category1.name } : null;
                    formData.category2 = this.category2 ? { id: this.category2.id, name: this.category2.name } : null;
                    formData.category3 = this.category3 ? { id: this.category3.id, name: this.category3.name } : null;
                    formData.category4 = this.category4 ? { id: this.category4.id, name: this.category4.name } : null;
                    formData.category5 = this.category5 ? { id: this.category5.id, name: this.category5.name } : null;
                    formData.category6 = this.category6 ? { id: this.category6.id, name: this.category6.name } : null;
                    formData.region_selected = this.region_selected ? { id: this.region_selected.id, name: this.region_selected.name } : null;
                    formData.created_date = this.date;
                    formData.draftIndex = this.draftIndex;
                    this.$store.dispatch('saveTrackAsDraft', { trackData:formData } );
                    this.saving = false;
                }
            }
            );
        },
        getCategoryById(id){
            const catObject = this.categoriesFull.find( (cat)=>{
                return cat.id == id;
            } );
            return catObject;
        },
        restoreDraft( draft ){
            this.draftIndex = draft.draftIndex;
            this.categoryLevel = draft.categoryLevel;
            if( draft.category1 ){
                this.selectCategory(1, this.getCategoryById( draft.category1.id ), true );
            }
            if( draft.category2 ){
                this.selectCategory(2, this.getCategoryById( draft.category2.id ), true );
            }
            if( draft.category3 ){
                this.selectCategory(3, this.getCategoryById( draft.category3.id ), true );
            }
            if( draft.category4 ){
                this.selectCategory(4, this.getCategoryById( draft.category4.id ), true );
            }
            if( draft.category5 ){
                this.selectCategory(5, this.getCategoryById( draft.category5.id ), true );
            }
            if( draft.category6 ){
                this.selectCategory(6, this.getCategoryById( draft.category6.id ), true );
            }
            
            this.region_selected = draft.region_selected;
            this.tags = draft.tags;
            this.scale = draft.scale;
            this.date = draft.date;
            this.photo_is_mine = draft.photo_is_mine;
            this.disclaimer = draft.disclaimer;
            this.description = draft.description;

            window.vueApp.map.flyTo( { center: draft.geo_location.coordinates, zoom:10} );

            this.mapTooltipShown = false;
        },
        uploadPhoto(newTrackId){
            if( this.photoFile ){
                var formData = new FormData();
                this.$store.dispatch('uploadTrackPhoto', { id:newTrackId, photoFile:this.photoFile, 
                    successCallback: (result)=>{
                        console.log( "[AddTrack] Success uploading photo", result );
                        this.successShown = true;
                        this.$store.dispatch('fetchTracks', {});
                        this.saving = false;
                    },                     
                    errorCallback: (error)=>{
                        console.log( "[AddTrack] Error uploading photo", error.toJSON() );
                        this.saving = false;
                        alert( this.$t('validation.problem-uploading-photo'));
                    }
                }, 
                );
            }else{
                this.successShown = true;
                this.$store.dispatch('fetchTracks', {});
                this.saving = false;
            }
            
        },
        getCategoriesList( index){
            return this["categories"+index];
        },
        getCategory( index){
            console.log( "getCategory: ", index );
            return this["category"+index];
        }
    },

    computed: {
        isDesktop() {
            let viewportWidth = window.innerWidth;
            return viewportWidth >= 416;
        },
        ...mapState( ['regions', 'categories','categories2','categories3','categories4','categories5','categories6', 'categoriesFull'])
    }, 

}
</script>

<style>

.reducedPanelContents{
  /* height: auto !important; */
}

.fieldDescription {
  font-family: "Ubuntu";
  color: #423735;
  font-size: 14px;
  margin: 5px 0px;
}

.typesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
  margin: 20px 0px;
}

.groupContainer {
  border: solid 1px #ceb6b2;
  border-radius: 10px;
  padding: 30px 10px;
  box-sizing: border-box;
  text-align: center;
}


/* Round checkbox */
.round {
  position: relative;
}

.round label {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ceb6b2;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #ceb6b2;
  border-color: #ceb6b2;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.radioContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.radioContainerCell1 {
  border-right: solid 1px #ceb6b2;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioContainerCell2 {
  font-family: "Ubuntu";
  color: #423735;
  font-size: 14px;
  padding: 0px 20px;
  align-items: center;
  display: flex;
}


.successMessage > .trackTitle{
    margin-bottom: 20px;
}
.successMessage .defaultButton{
    width: 60%;
    margin-top: 50px;
    padding: 15px 20px;
}

.successMessage{
    text-align: center;
    font-family: 'Ubuntu';
}

.emptyPreview2{
    border: solid 1px #CEB6B2;
    border-radius: 12px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photoCaptureComponent .baseButton{
    background-color: #FFC352 !important;
    padding-bottom: 5px !important;
}

.typeButtonContainer{
    font-family: 'Ubuntu';
    font-size: 16px;
}
.typeButton{
  background-color: #FFD480;
  height: 80px;
  width: 100%;
  border-radius: 10px;
  padding: 15px 10px;
  box-sizing: border-box;
  text-align: center;
  background-size: cover;
}
.typeButton img{
    height: 50px;
}

.previewImage{
    box-sizing: border-box;
    width: 100%;
}

.v-list-item__title {
    flex: 1 1 100%;
    /* overflow: hidden; */
    white-space: pre-wrap !important;
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    line-height: 2 !important;
}

.photoButtonsContainerDesktop{
    grid-template-columns: 1fr !important;
}

</style>