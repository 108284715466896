<template>
    <div>
        <div id="homeAbout">
            <div class="homeSectionTitle" style="color: #ffffff;">{{ $t('about.title') }}</div>
        </div>


        <div class="homeAboutContents">
            <div class="aboutContents">
            <div class="">
                
                <div class="homeSectionSubtitle aboutSubtitle" style="margin-bottom: 40px;" v-html="$t('about.text0')">
                </div>
                <div style="font-family: 'Ubuntu'; font-size: 16px;" v-html="$t('about.text1')">
                </div>
            </div>
            <div>
                <img class="homeAboutIllustration" :src="publicPath+'img/assets/home_about.png'" alt="">
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {    
        ...mapState( ['publicPath'])
    }, 
    
}
</script>

<style scoped>
#homeAbout {
  display: none;
}
.homeAboutContents{
  display: none;
}


/* desktop */

@media only screen and (min-width: 416px) {
  #homeAbout {
    display: block;
    /* height: 400px; */
    background-color: #e86e59;
    text-align: center;
    padding: 70px 0px;
    color: #ffffff;
  }

  .homeAboutContents {
    display: flex;
    background-color: #fff6f4;
    position: relative;
    justify-items: center;
    flex-direction: column;
    padding: 100px 20px 30px;
    align-items: center;
  }

  .homeAboutIllustration {
    display: none;
  }
}


@media only screen and (min-width: 716px) {
  .homeAboutIllustration {
    display: block;
    width: 250px;
    margin-top: 120px;
  }
}

@media only screen and (min-width: 1024px) {
  .homeAboutIllustration {
    width: 450px;
    margin-top: 100px;
  }
  .aboutContents {
    max-width: 900px;
  }
}
</style>