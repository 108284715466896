<template>
    <div>
        <div id="homeImpactBanner">
            <div class="sectionTitle">{{ $t('impactPage.share-the-impact') }}</div>
            <div style="text-align: center; align-items: center; width: max-content; margin: 30px auto;">
                <a class="actionButton" style="color: #ffffff;" href="/impact">{{ $t('impactPage.go-to-impact') }}</a>
                <!-- <div class="" style="color: #ffffff;">{{ $t('impactPage.go-to-impact') }}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {    
        ...mapState( ['publicPath'])
    }, 
    
}
</script>

<style scoped>

.sectionTitle {
    font-family: "Odibee Sans";
    font-size: 40px;
    color: #e86e59;
    text-align: center;
}

.actionButton {
    font-family: "Odibee Sans";
    font-size: 20px;
    color: #000000DD !important;
    background-color: #e86e59;
    width: max-content;
    padding: 10px 30px;
}

#homeImpactBanner {
  display: none;
}
.homeImpactBannerContents{
  display: none;
}


/* desktop */

@media only screen and (min-width: 416px) {
  #homeImpactBanner {
    display: flex;
    padding: 70px 0px;
    color: #ffffff;
    background-image: url(/img/impact_banner.png);
    background-size: cover;
    background-position: center;
    height: 500px;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
  }

  /* .homeImpactBannerContents {
    display: flex;
    background-color: #fff6f4;
    position: relative;
    justify-items: center;
    flex-direction: column;
    padding: 100px 20px 30px;
    align-items: center;
  }

  .homeImpactBannerIllustration {
    display: none;
  } */
}

/* 
@media only screen and (min-width: 716px) {
  .homeImpactBannerIllustration {
    display: block;
    width: 250px;
    margin-top: 120px;
  }
}

@media only screen and (min-width: 1024px) {
  .homeImpactBannerIllustration {
    width: 450px;
    margin-top: 100px;
  }
  .aboutContents {
    max-width: 900px;
  }
} */
</style>