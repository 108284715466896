<template>
    <div id="homeStats">
    <div class="homeStatsCell">
      <div class="homeStatsCellTitle">{{ $t('stats.total-records') }}</div>
      <div class="homeStatsCellIcon">
        <img :src="`${publicPath}img/assets/total_tracks_icon.png`" alt="" style="width: 100px;">
      </div>
      <div class="homeStatsCellValue">{{stats.total_records}}</div>
    </div>

    <div class="homeStatsVerticalDivider"></div>

    <div class="homeStatsCell" style="width: 460px; text-align: left;">
      <div class="homeStatsCellTitle">{{ $t('stats.scale-of-impact') }}</div>
      <div class="homeStatsCellIcon" style="padding-top: 30px;">
        <!-- <img :src="`${publicPath}img/assets/conservation_icon.png`" alt="" style="width: 440px;"> -->
        <img :src="`${publicPath}img/customicons/placemark.png`" :style=" { width: '30px', left: '-15px', position: 'relative', 'margin-left': getImpactSliderPosition( Math.round(stats.mean_scale) ) }"/>
          <div class="trackDetailsChart"></div>
          <div class="scaleTicks">
              <div class="scaleTicksMarks" />
              <div class="scaleTicksMarks" />
              <div class="scaleTicksMarks" />
              <div class="scaleTicksMarks" style="border-right: solid 1px #CEB6B2;"/>
          </div>
          <div class="scaleLegend" style="font-size: 20px;">
              <div class="scaleLegendItem">{{ $t('scale.negative') }}</div>
              <div class="scaleLegendItem" style="text-align: center;">{{ $t('scale.neutral') }}</div>
              <div class="scaleLegendItem" style="text-align: right;">{{ $t('scale.positive') }}</div>
          </div>
      </div>
      <div class="homeStatsCellValue"></div>
    </div>

    <div class="homeStatsVerticalDivider"></div>
    
    <div class="homeStatsCell">
      <div class="homeStatsCellTitle">{{ $t('stats.total-area') }}</div>
      <div class="homeStatsCellIcon">
        <img :src="`${publicPath}img/assets/total_area_icon.png`" alt="" style="width: 129px;">
      </div>
      <div class="homeStatsCellValue">{{ squareMetersToHectares( stats.total_area*1000 ) }} <span style="font-size: 0.8em;">Ha</span></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import utilsMixins from '../../mixins/utilsMixins'

export default {
    mixins: [utilsMixins],    
    data(){
        return {
        }
    }, 
    computed: {    
        ...mapState( [ 'publicPath', 'stats','tracks','draftTracks', 'categories','categoryColors', 'impacts'])
    }, 
}
</script>

<style scoped>
#homeStats {
  display: none;
}
.homeStatsCell {
  text-align: center;
  display: inline-block;
  margin: 0px 2%;
  transform: scale(0.75);
}
.homeStatsCellTitle {
  font-family: "Odibee Sans";
  font-size: 26px;
  margin-bottom: 30px;
  text-align: center;
}
.homeStatsCellIcon {
  height: 100px;
}
.homeStatsCellValue {
  font-family: "Odibee Sans";
  font-size: 45px;
  color: #e86e59;
}
.homeStatsVerticalDivider {
  background-color: #ecd9d5;
  width: 1px;
  margin: 70px 0px;
}



/* desktop */

@media only screen and (min-width: 416px) {

  #homeStats {
    display: flex;
    padding: 110px 0px 40px;
    background-color: #ffffff;
    justify-content: center;
    transform: scale(0.6);
  }
}


@media only screen and (min-width: 716px) {
  #homeStats {
    transform: scale(0.9);
  }
}

@media only screen and (min-width: 1024px) {
  .homeStatsCell {
    text-align: center;
    display: inline-block;
    margin: 0px 4%;
    transform: unset;
  }

  #homeStats {
    transform: scale(1);
  }
}
</style>