<template>
    <transition name="fade"> 
        <div class="welcomeContainer" v-if="!isDesktop && welcomeShown">

            <div :class="{ welcomePanel: true, welcomePanelBurmese:$i18n.locale=='bu'}">
            <div class="welcomePhoto">
                <img :src="`${publicPath}img/assets/welcome_illustration.jpeg`" alt="" />
            </div>
            <div class="textContainer">
                <p class="welcomeTitle" v-html="$t('welcome.mingalabar')"></p>
                <div v-html="$t('welcome.text0')"></div>
            </div>
            <div style="padding: 20px;">
                <button class="defaultButton" @click="hideWelcome" style="width: 100%; padding: 12px;">{{ $t('general.continue') }}</button>
            </div>
            </div>
        </div>
        </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    isDesktop: Boolean,
    welcomeShown: Boolean,
    hideWelcome: Function,
  },
  components: {},
  computed: {
    ...mapState(["publicPath"]),
  },
};
</script>

<style scoped>
.welcomeContainer {
  padding: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 70px;
  z-index: 1000;
}

.welcomePanel {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  color: #423735;
  font-size: 16px;
  font-family: "Ubuntu";
  display: flex;
  flex-direction: column;
  max-height: 82vh;
}

.welcomePanelBurmese {
  font-size: 12px;
}
.welcomeTitle {
  font-family: "Odibee Sans";
  font-size: 24px;
  padding: 5px;
  margin-bottom: 0px !important;
}

.welcomePhoto > img {
  position: relative;
  width: 100%;
}

.textContainer {
  padding: 10px 10px 10px;
  text-align: center;
  overflow-y: auto;
}
</style>