<template>
    <div>
        <div id="homeHero">
        <img class="heroImage" v-if="$i18n.locale!='bu'" :src="`${publicPath}img/assets/desktop_home.jpeg`" />
        <img class="heroImage" v-if="$i18n.locale=='bu'" :src="`${publicPath}img/assets/desktop_home_bu.jpeg`" />
        <div class="homeTopMenu">
            <div class="homeTopMenuButton" @click="scrollToSection( '#viewContainer' )">{{ $t('menu.map') }}</div>
            <div class="homeTopMenuButton" @click="scrollToSection( '#homeAbout' )">{{ $t('menu.about') }}</div>
            <div class="homeTopMenuButton" @click="scrollToSection( '#homeMainContents' )">{{ $t('menu.get-involved') }}</div>
            <div class="homeTopMenuButton" @click="scrollToSection( '#faqSection' )">{{ $t('menu.faq') }}</div>
            <a class="homeTopMenuButton" href="/impact">{{ $t('menu.impact') }}</a>
            <!-- <a class="homeTopMenuButton" :href="'https://blog.projectyokkasoe.org/'+($i18n.locale=='bu'?'my':'en')+'/blog/'">{{ $t('menu.blog') }}</a> -->
            <div v-if="$i18n.locale=='bu'" class="homeTopMenuButton" @click="changeLanguage( 'en' )">English</div>
            <div v-if="$i18n.locale!='bu'" class="homeTopMenuButton" @click="changeLanguage( 'bu' )">မြန်မာ</div>
        </div>
    </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        scrollToSection: Function,
        changeLanguage: Function
    },
    components: {
    },
    computed: {    
        ...mapState( ['publicPath'])
    }, 
    
}
</script>

<style scoped>

#homeHero {
  display: none;
}

/* desktop */

@media only screen and (min-width: 416px) {
  #homeHero {
    display: block;
  }
  .heroImage {
    width: 100%;
  }
}



</style>