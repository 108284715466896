<template>
    <div id="homeMainContents">
        <div class="homeSectionTitle" id="sectionGettingInvolved">{{ $t('home.getting-involved') }}</div>
        <div class="homeSectionSubtitle" style="width: 470px; margin-top: 20px;" v-html="$t('home.getting-involved-subtitle')">
        
        </div>
        <div class="involvedContents">
        <div style="text-align: center;">
            <!-- <img :src="publicPath+'img/assets/smartphone_photo.png'" style="width: 350px;" alt=""> -->
            <iframe width="560" height="315" src="https://www.youtube.com/embed/I0XVp4t2Me0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="involvedSteps">
            <p>{{ $t('home.getting-involved-text0') }}</p>
            <!-- <p><div class="numberedBullet">1</div> <div class="numberedBulletText" style="top: 10px; position: relative;" v-html="$t('home.getting-involved-text1')"> <br/><a href="https://www.projectyokkasoe.org/">www.projectyokkasoe.org</a></div></p> -->
            <p><div class="numberedBullet">1</div> <div class="numberedBulletText" style="top: 0px; position: relative;" v-html="$t('home.getting-involved-text2')"></div></p>
            <p><div class="numberedBullet">2</div> <div class="numberedBulletText" v-html="$t('home.getting-involved-text3')"></div></p>
            <p v-html="$t('home.getting-involved-text4')"></p>
            <p style="font-family: 'Odibee Sans'; font-size: 24px; color: #E86E59; text-align: center;" v-html="$t('home.getting-involved-text5')"></p>
        </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {    
        ...mapState( ['publicPath'])
    }, 
    
}
</script>

<style scoped>
#homeMainContents {
  display: none;
}


/* desktop */

@media only screen and (min-width: 416px) {

  #homeMainContents {
    display: flex;
    background-color: #ffffff;
    position: relative;
    justify-items: center;
    flex-direction: column;
    padding: 100px 20px 30px;
    text-align: center;
    align-items: center;
  }
}

</style>