<template>
  <div class="shareButton">
    <div class="textLabel">{{ label }}</div>
    <div class="icon">
      <img :src="'/share/'+icon+'.png'" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: String,
        icon: String
    }
}
</script>

<style scoped>

.shareButton{
    background-color: #E86E59;
    color: #ffffff;
    padding: 5px;
    text-align: center;
    width: 240px;
    margin-bottom: 5px;
    cursor: pointer;
    box-sizing: content-box;
}
.shareButton:hover{
    background-color: #f08370;

}

.textLabel{
    display: inline-block;
    top: -8px;
    position: relative;
    margin-right: 10px;
    font-size: 24px;
    width: 100px;
}
.icon{
    display: inline-block;
    top: 5px;
    position: relative;
}
.icon img{
    display: inline-block;
    width: 50px;
    height: 50px;
}

</style>