<template>
    <div class="photoCaptureComponent">
        <slot name="preview" v-bind:imageData="imageData" v-bind:openCamera="openCamera" v-bind:openFile="openFile">
            <div class="photoPreviewContainer">
                <div class="emptyPreview" v-if="!imageData" />
                <img :src="imageData" alt="" v-if="imageData" />
            </div>
            <div class="photoButtonsContainer">
                <div class="baseButton cameraButton" @click="openCamera">CAM2</div>
                <div></div>
                <div class="baseButton cameraRollButton" @click="openFile">FILE2</div>
            </div>
        <!-- <label for="cameraFileInput">
            <span class="btn">Open camera</span> -->
        </slot>
        <input
            id="cameraOrFileInput"
            ref="cameraOrFileInput"
            type="file"
            accept="image/*"
            capture="environment"
            @change="onPictureChanged"
        />
        <!-- </label> -->
    </div>
</template>



<script>

//import ImageBlobReduce from 'image-blob-reduce';

export default {
    
    data() {
        return{
            imageData: null, 
        }
    },
    methods: {
        resizeImage( file, MAX_WIDTH, MAX_HEIGHT, successCallback ) {
            if (file) {
            let reader = new FileReader();

            // Set the image for the FileReader
            reader.onload = (e) => {
                let img = document.createElement("img");
                img.onload = ()=>{
                    // Create your canvas
                    let canvas = document.createElement("canvas");
                    let ctx_in = canvas.getContext("2d");
                    ctx_in.drawImage(img, 0, 0);
                    let width = img.width;
                    let height = img.height;

                    // Add the resizing logic
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    //Specify the resizing result
                    canvas.width = width;
                    canvas.height = height;
                    let ctx_out = canvas.getContext("2d");
                    ctx_out.drawImage(img, 0, 0, width, height);

                    const dataurl = canvas.toDataURL(file.type);
                    const dataBlob = this.dataURLtoBlob( dataurl );
                    successCallback( dataBlob );
                    //document.getElementById("output").src = dataurl;                    
                }                    
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
            }
        },
        dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type:mime});
        }, 
        openCamera(){
            this.$refs.cameraOrFileInput.setAttribute("capture","camera");
            document.getElementById("cameraOrFileInput").click();
        },
        openFile(){
            this.$refs.cameraOrFileInput.removeAttribute("capture");
            document.getElementById("cameraOrFileInput").click();

        }, 
        onPictureChanged( event ){
            console.log( "pictureChanged event:", event );
            var file = event.target.files[0];
            /* if( file.size > 1000000 ){
                alert( this.$t('addTrack.large-image-warning') );
                return;
            } */
            this.resizeImage( file, 1024, 1024, (reducedFile)=>{
                reducedFile.name = file.name;
                this.imageData = window.URL.createObjectURL(reducedFile)
                this.$emit( 'change', reducedFile, this.imageData );                
            } );

            /* let ir = new ImageBlobReduce();
            ir
                .toBlob( file, { max: 1000 })
                .then(blob => { 
                    console.log( "REduce image: ", blob);
                    const imageUrl = window.URL.createObjectURL(file);
                    let reducedImageFile = new File([blob], "name.jpeg", { type: "image/jpeg", });
                    this.imageData = imageUrl;
                    this.$emit( 'change', reducedImageFile, this.imageData );
                 }); */
                
            /* this.imageData = window.URL.createObjectURL(file);
            this.$emit( 'change', file, this.imageData ); */
        },
        getImageData(){
            return this.imageData();
        }
    }
}
</script>


<style lang="sass" scoped>

.photoCaptureComponent
    .emptyPreview
        border: solid 1px #333333
        min-height: 200px

    .baseButton
        background-color: #cccccc
        padding: 10px 20px
        text-align: center

    .photoPreviewContainer
        width: 100%
        background-color: #cccccc
    
    .photoButtonsContainer
        display: grid
        grid-template-columns: 5fr 2fr 5fr
        align-items: center
        justify-content: center      
        margin-top: 15px  
    
    
    .cameraButton
    
    .cameraRollButt

    input#cameraOrFileInput
         display: none

</style>