import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../Home.vue'
import Impact from '../Impact.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/impact",
    name: "Impact",
    component: Impact,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
