export default {
  data() {
    return {
      impactMapping: {
        '1': '99%',
        '2': '75%',
        '3': '50%',
        '4': '25%',
        '5': '1%',
        '6': '50%',
      },
    }
  },
  methods: {
    getImpactSliderPosition(impactId) {
      return this.impactMapping[impactId];
    },
    squareMetersToHectares(meters) {
      if (!meters)
        return "Unknown";
      //meters = meters.split(".").join("").split(",").join("");
      let ha = (parseInt(meters) * 0.0001).toFixed(2);
      return ha;
    },
  }
};