<template>
  <div class="appDiv">
    <!-- <div class="topBar"></div> -->
    <header-bar></header-bar>

    

    <div class="mainContainer" v-if="data_tree">
      <img  src="./assets/img/head_animals.png" class="animalsHeader">
      <div class="headerTitle" style="max-width: 820px;">
        <div class="subtitle1" :style="{'font-size': $i18n.locale == 'bu' ? '28px' : '36px'}">{{ $t('impactPage.title1') }}</div>
        <div class="subtitle2" :style="{'font-size': $i18n.locale == 'bu' ? '40px' : '64px', color: '#E86E59'}">{{ $t('impactPage.title2') }}</div>
        <div class="subtitle3" :style="{'font-size': $i18n.locale == 'bu' ? '31px' : '48px'}">{{ $t('impactPage.title3') }}</div>
      </div>

      <div class="sectionContainer" v-if="$i18n.locale=='en'">
        <div class="posterContainer" id="poster0">
          <img class="posterBackground" src="./assets/img/posters/totals.png" />
          <div class="posterText" style="line-height: 70px;">
            <div :style="{'font-size': $i18n.locale=='bu' ? '34px' : '49px'}">{{ $t('impactPage.since') }} {{getSinceDate()}}</div>
            <div style="font-size: 50px; position: relative; top: -17px; color: #E86E59;"><span style="font-size: 75px">{{data_stats.numRecords}}</span> {{ $t('impactPage.records') }}</div>
            <div :style="{'font-size': $i18n.locale=='bu'?'35px':'45px', position: 'relative', top: $i18n.locale=='bu'?'-35px':'-45px' }">{{ $t('impactPage.submitted') }}</div>
          </div>
        </div>
        <div class="posterSide">
          <div class="shareLabel">{{ $t('impactPage.share') }}</div>
          <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster0', 0, 'facebook')"/>
          <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster0', 0, 'twitter')" />
          <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster0', 0, 'download')" />
        </div>
      </div>
      <div class="sectionContainer" v-if="$i18n.locale=='bu'">
        <div class="posterContainer" id="poster0">
          <img class="posterBackground" src="./assets/img/posters/totals_bu.png" />
          <div class="posterText" style="line-height: 70px;">
            <div :style="{'font-size': $i18n.locale=='bu' ? '34px' : '49px'}">{{getSinceDate()}} {{ $t('impactPage.since') }}</div>
            <div :style="{'font-size': $i18n.locale=='bu'?'35px':'45px', position: 'relative', top: $i18n.locale=='bu'?'-25px':'-45px' }">{{ $t('impactPage.submitted') }}</div>
            <div style="font-size: 40px; position: relative; top: -37px; color: #E86E59;">{{ $t('impactPage.records') }}<span style="font-size: 45px;left: 20px; position: relative; margin-right: 20px;">{{data_stats.numRecords}}</span>ခု</div>
          </div>
          <div class="posterFooterText">{{$t('impactPage.posterFooter')}}</div>
        </div>
        <div class="posterSide">
          <div class="shareLabel">{{ $t('impactPage.share') }}</div>
          <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster0', 0, 'facebook')"/>
          <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster0', 0, 'twitter')" />
          <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster0', 0, 'download')" />
        </div>
      </div>

      <div class="sectionContainer">
         <div :class="{ posterSide:true,  topRecordsRegionSelect:true}">
          <div>
            <div style="font-size: 30px; color: #E86E59; max-width: 340px;">{{ $t('impactPage.most-at-risk') }} </div>
            <select name="" id="" class="commonDropdown homeTownDropdown" v-model="selectedRegionIndex">
              <option v-for="(region, $index) in data_stats.sections.mostRecordsbyRegion" :value="$index">{{region["name_"+$i18n.locale]}}</option>
            </select>
          </div>
        </div>

        <div class="posterContainer" id="poster1" v-if="$i18n.locale=='en'">
          <img class="posterBackground" :src="'/img/most_records_by_region/'+data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].id+'.png'" />
          <div class="posterText" style="top: 46px; left: 15px;">
            <div :class="{ topRegionsTitleBurmese:$i18n.locale=='bu', topRegionsTitleEnglish:$i18n.locale=='en' }">{{ $t('impactPage.most-records') }} <br/>{{ $t('impactPage.captured-in') }} <br/><span style="color: #ffffff;">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex]["name_"+$i18n.locale]}}</span><br/><div style="font-size: 24px; position: relative; top: -5px;">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].total}} {{ $t('impactPage.in-total') }}</div></div>
            
            <div v-if="data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[0]" :class="{ rank1Item:true, rank1ItemBurmese: $i18n.locale=='bu' }" style="top: 185px">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[0]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[0].value}} {{ $t('impactPage.records') }}</span>
            </div>
             <div v-if="data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[1]"  :class="{ rank1Item:true, rank1ItemBurmese: $i18n.locale=='bu' }" style="top: 265px">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[1]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[1].value}} {{ $t('impactPage.records') }}</span>
            </div>
             <div v-if="data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[2]"  :class="{ rank1Item:true, rank1ItemBurmese: $i18n.locale=='bu' }" style="top: 360px">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[2]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[2].value}} {{ $t('impactPage.records') }}</span>
            </div>
          </div>
        </div>
        <div class="posterContainer" id="poster1" v-if="$i18n.locale=='bu'">
          <img class="posterBackground" :src="'/img/most_records_by_region/bu/'+data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].id+'.png'" />
          <div class="posterText" style="top: 46px; left: 15px;">
            <div :class="{ topRegionsTitleBurmese:$i18n.locale=='bu', topRegionsTitleEnglish:$i18n.locale=='en' }">{{ $t('impactPage.most-records') }} <br/>{{ $t('impactPage.captured-in') }} <br/><span style="color: #ffffff;">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex]["name_"+$i18n.locale]}}</span><br/><div style="font-size: 24px; position: relative; top: -5px;">{{ $t('impactPage.in-total') }} &nbsp;&nbsp;{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].total}}ခု</div></div>
            
            <div v-if="data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[0]" :class="{ rank1Item:true, rank1ItemBurmese: $i18n.locale=='bu' }" style="top: 185px">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[0]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[0].value}}ခု</span>
            </div>
             <div v-if="data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[1]"  :class="{ rank1Item:true, rank1ItemBurmese: $i18n.locale=='bu' }" style="top: 265px">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[1]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[1].value}}ခု</span>
            </div>
             <div v-if="data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[2]"  :class="{ rank1Item:true, rank1ItemBurmese: $i18n.locale=='bu' }" style="top: 360px">{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[2]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.mostRecordsbyRegion[selectedRegionIndex].ranking[2].value}}ခု</span>
            </div>
          </div>
          <div class="posterFooterText">{{$t('impactPage.posterFooter')}}</div>
        </div>
        <div class="posterSide">
          <!-- <div>
            <div style="font-size: 36px; color: #E86E59;">{{ $t('impactPage.most-at-risk') }} </div>
            <select name="" id="" class="commonDropdown homeTownDropdown" v-model="selectedRegionIndex">
              <option v-for="(region, $index) in data_stats.sections.mostRecordsbyRegion" :value="$index">{{region.name_en}}</option>
            </select>
          </div> -->
         <!--  <v-select
              v-model="region_selected" :items="regions"
              :return-object="true"
              outlined
              v-on:change="regionChanged()"
          ></v-select> -->
          <div class="shareLabel">{{ $t('impactPage.share') }}</div>
          <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster1', 1, 'facebook')"/>
          <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster1', 1, 'twitter')" />
          <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster1', 1, 'download')" />
        </div>
      </div>

      <div class="sectionContainer">
        <div class="posterContainer" id="poster2"  v-if="$i18n.locale=='en'">
          <img class="posterBackground" src="./assets/img/posters/high_risk_areas.png" />
          <div class="posterText" style="top: 46px; left: 15px;">
            <div :class="{ highRiskAreasTitleBurmese:$i18n.locale=='bu', highRiskAreasTitleEnglish:$i18n.locale=='en' }">{{ $t('impactPage.high-risk-areas') }}</div>
            
            <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="top: 212px">{{data_stats.sections.highRiskAreas[0]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.highRiskAreas[0].value}} {{ $t('impactPage.records') }}</span>
            </div>
             <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="top: 285px">{{data_stats.sections.highRiskAreas[1]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.highRiskAreas[1].value}} {{ $t('impactPage.records') }}</span>
            </div>
             <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="top: 356px">{{data_stats.sections.highRiskAreas[2]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.highRiskAreas[2].value}} {{ $t('impactPage.records') }}</span>
            </div>
          </div>
        </div>
        <div class="posterContainer" id="poster2"  v-if="$i18n.locale=='bu'">
          <img class="posterBackground" src="./assets/img/posters/high_risk_areas_bu.png" />
          <div class="posterText" style="top: 46px; left: 15px;">
            <div :class="{ highRiskAreasTitleBurmese:$i18n.locale=='bu', highRiskAreasTitleEnglish:$i18n.locale=='en' }">{{ $t('impactPage.high-risk-areas') }}</div>
            
            <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="top: 212px">{{data_stats.sections.highRiskAreas[0]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.highRiskAreas[0].value}}ခု</span>
            </div>
             <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="top: 285px">{{data_stats.sections.highRiskAreas[1]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.highRiskAreas[1].value}}ခု</span>
            </div>
             <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="top: 356px">{{data_stats.sections.highRiskAreas[2]["name_"+$i18n.locale]}}<br/>
              <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.highRiskAreas[2].value}}ခု</span>
            </div>
          </div>
          <div class="posterFooterText">{{$t('impactPage.posterFooter')}}</div>
        </div>
        <div class="posterSide">
          <div class="shareLabel">{{ $t('impactPage.share') }}</div>
          <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster2', 2, 'facebook')"/>
          <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster2', 2, 'twitter')" />
          <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster2', 2, 'download')" />
        </div>
      </div>



      <div class="sectionContainer" style="padding-bottom: 0px;">
          <div class="subtitle3 top3Subtitle" :style="{'font-size': $i18n.locale == 'bu' ? '38px' : '48px', 'max-width': '940px' }">{{ $t('impactPage.agree-share') }}</div>

          <div class="wildLifeSuperContainer" v-if="$i18n.locale=='en'">            
            <div class="posterContainer posterContainerDouble" id="poster3">
              <img class="posterBackground" src="./assets/img/posters/top3_categories.png" />
              
              <div class="categoryButton" :style="{position: 'absolute', top: '130px', 'left': '27px', 'width': '205px', height: '95px', 'background-image': 'url(/img/category_icons/'+data_stats.sections.top3Categories[0].id+'.png)' }" />
              <div class="categoryButton" :style="{position: 'absolute', top: '243px', 'left': '27px', 'width': '205px', height: '95px', 'background-image': 'url(/img/category_icons/'+data_stats.sections.top3Categories[1].id+'.png)' }" />
              <div class="categoryButton" :style="{position: 'absolute', top: '357px', 'left': '27px', 'width': '205px', height: '95px', 'background-image': 'url(/img/category_icons/'+data_stats.sections.top3Categories[2].id+'.png)' }" />

              <div class="posterText" style="top: 15px; left: 280px;">            
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 145px; color: #E86E59;">{{data_stats.sections.top3Categories[0]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.top3Categories[0].value}} {{ $t('impactPage.records') }}</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 258px; color: #E86E59;">{{data_stats.sections.top3Categories[1]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.top3Categories[1].value}} {{ $t('impactPage.records') }}</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 372px; color: #E86E59;">{{data_stats.sections.top3Categories[2]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.top3Categories[2].value}} {{ $t('impactPage.records') }}</span>
                </div>
              </div>
            </div>
            <div class="posterContainer posterContainerDouble">
              <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster3', 3, 'facebook')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster3', 3, 'twitter')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster3', 3, 'download')" style="display: inline-block; width: 167px;"/>
            </div>
          </div>

          <div class="wildLifeSuperContainer" v-if="$i18n.locale=='bu'">            
            <div class="posterContainer posterContainerDouble" id="poster3">
              <img class="posterBackground" src="./assets/img/posters/top3_categories_bu.png" />
              
              <div class="categoryButton" :style="{position: 'absolute', top: '130px', 'left': '27px', 'width': '205px', height: '95px', 'background-image': 'url(/img/category_icons/'+data_stats.sections.top3Categories[0].id+'.png)' }" />
              <div class="categoryButton" :style="{position: 'absolute', top: '243px', 'left': '27px', 'width': '205px', height: '95px', 'background-image': 'url(/img/category_icons/'+data_stats.sections.top3Categories[1].id+'.png)' }" />
              <div class="categoryButton" :style="{position: 'absolute', top: '357px', 'left': '27px', 'width': '205px', height: '95px', 'background-image': 'url(/img/category_icons/'+data_stats.sections.top3Categories[2].id+'.png)' }" />

              <div class="posterText" style="top: 15px; left: 280px;">            
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 145px; color: #E86E59;">{{data_stats.sections.top3Categories[0]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.top3Categories[0].value}}ခု</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 258px; color: #E86E59;">{{data_stats.sections.top3Categories[1]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.top3Categories[1].value}}ခု</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 372px; color: #E86E59;">{{data_stats.sections.top3Categories[2]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.top3Categories[2].value}}ခု</span>
                </div>
              </div>
              <div class="posterFooterText">{{$t('impactPage.posterFooter')}}</div>
            </div>
            <div class="posterContainer posterContainerDouble">
              <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster3', 3, 'facebook')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster3', 3, 'twitter')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster3', 3, 'download')" style="display: inline-block; width: 167px;"/>
            </div>
          </div>
          
          <div class="wildLifeSuperContainer" v-if="$i18n.locale=='en'">            
            <div class="posterContainer posterContainerDouble" style=""  id="poster4">
              <img class="posterBackground" src="./assets/img/posters/top3_species.png" />

              <img style="position: absolute; top: 90px; right: 100px; height: 260px;" :src="'/img/species/'+data_stats.sections.top3WildlifeSold[0].id+'.png'" />
              <img style="position: absolute; bottom: 90px; right: 200px; height: 110px;" :src="'/img/species/'+data_stats.sections.top3WildlifeSold[1].id+'.png'" />
              <img style="position: absolute; bottom: 90px; right: 10px; height: 110px;" :src="'/img/species/'+data_stats.sections.top3WildlifeSold[2].id+'.png'" />

              <div class="posterText" style="top: 0px; left: 70px;">            
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 145px">{{data_stats.sections.top3WildlifeSold[0]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.top3WildlifeSold[0].value}} {{ $t('impactPage.records') }}</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 248px">{{data_stats.sections.top3WildlifeSold[1]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.top3WildlifeSold[1].value}} {{ $t('impactPage.records') }}</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 342px">{{data_stats.sections.top3WildlifeSold[2]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{data_stats.sections.top3WildlifeSold[2].value}} {{ $t('impactPage.records') }}</span>
                </div>
              </div>
            </div>
            <div class="posterContainer" style="">
              <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster4', 4, 'facebook')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster4', 4, 'twitter')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster4', 4, 'download')" style="display: inline-block; width: 167px;"/>
            </div>
          </div>
          
          <div class="wildLifeSuperContainer" v-if="$i18n.locale=='bu'">            
            <div class="posterContainer posterContainerDouble" style=""  id="poster4">
              <img class="posterBackground" src="./assets/img/posters/top3_species_bu.png" />

              <img style="position: absolute; top: 90px; right: 100px; height: 260px;" :src="'/img/species/'+data_stats.sections.top3WildlifeSold[0].id+'.png'" />
              <img style="position: absolute; bottom: 90px; right: 200px; height: 110px;" :src="'/img/species/'+data_stats.sections.top3WildlifeSold[1].id+'.png'" />
              <img style="position: absolute; bottom: 90px; right: 10px; height: 110px;" :src="'/img/species/'+data_stats.sections.top3WildlifeSold[2].id+'.png'" />

              <div class="posterText" style="top: 0px; left: 70px;">            
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 145px">{{data_stats.sections.top3WildlifeSold[0]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.top3WildlifeSold[0].value}}ခု</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 248px">{{data_stats.sections.top3WildlifeSold[1]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.top3WildlifeSold[1].value}}ခု</span>
                </div>
                <div :class="{ rank2Item:true, rank2ItemBurmese: $i18n.locale=='bu' }" style="left: 0px; top: 342px">{{data_stats.sections.top3WildlifeSold[2]["name_"+$i18n.locale]}}<br/>
                  <span style="font-size: 20px; color: #E86E59">{{ $t('impactPage.records') }} &nbsp;&nbsp;{{data_stats.sections.top3WildlifeSold[2].value}}ခု</span>
                </div>
              </div>
              <div class="posterFooterText">{{$t('impactPage.posterFooter')}}</div>
            </div>
            <div class="posterContainer" style="">
              <share-button label="FACEBOOK" icon="facebook" @click.native="takeScreenshot('poster4', 4, 'facebook')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="TWITTER" icon="twitter" @click.native="takeScreenshot('poster4', 4, 'twitter')" style="display: inline-block; margin-right: 5px; width: 167px;"/>
              <share-button label="DOWNLOAD" icon="download" @click.native="takeScreenshot('poster4', 4, 'download')" style="display: inline-block; width: 167px;"/>
            </div>
          </div>
        </div>
       
       


      <div style="width: 100%; height: 1px; background-color: #CEB6B2; margin: 70px 0px 120px;"></div>

      <div v-if="$i18n.locale=='en'" class="subtitle3" style="font-size: 40px; text-transform: UPPERCASE; margin-bottom: -10px">{{ $t('impactPage.find-out-more') }}</div>
      <div v-if="$i18n.locale=='bu'" class="subtitle3" style="font-size: 40px; text-transform: UPPERCASE; margin-bottom: 10px">{{ $t('impactPage.find-out-more') }}</div>
      <div class="subtitle3" style="font-size: 23px; text-transform: UPPERCASE; margin-bottom: 10px;">{{ $t('impactPage.find-out-more-sub') }}</div>
      <div class="subtitle3" style="font-size: 18px;  margin-bottom: 20px; font-family: 'Ubuntu Mono';">
        <span v-for="(item, $index) in getSunburstBreadcrumbs()">{{item.name}} <span style="position: relative; top: -2px; font-size: 15px; color: #e86e59;" v-if="$index < getSunburstBreadcrumbs().length-1">▶ </span> </span>
      </div>
     <!--  <div class="subtitle3" style="font-size: 23px; text-transform: UPPERCASE; margin-bottom: 0px;">{{ $t('impactPage.by-categories') }}</div> -->

      <div class="sectionContainer sunburstSection">
        <div id="sunburstChart" style="width:800px; height:800px;"></div>

        <div class="sunburstDropdownAndShare">
          <select name="" id="" class="commonDropdown" v-model="selectedSunburstRegionId" style="width: 240px; margin: 20px 0px; " @change="regionSunburstChanged">
              <option value="0">{{ $t('impactPage.all') }}</option>
              <option v-for="(region, $index) in data_stats.sections.mostRecordsbyRegion" :value="region.id">{{region["name_"+$i18n.locale]}}</option>
            </select>
            <div class="shareLabel" style="margin: 20px 5px 10px;">{{ $t('impactPage.share') }}</div>
            <div class="sunburstShare">
              <div style="margin: 5px; cursor: pointer; display: inline-block;" @click="takeScreenshot('sunburstChart', 5, 'facebook')"><img style="width: 50px;" :src="'/share/facebook_clr.png'" /></div>
              <div style="margin: 5px; cursor: pointer; display: inline-block;" @click="takeScreenshot('sunburstChart', 5, 'twitter')"><img style="width: 50px;" :src="'/share/twitter_clr.png'" /></div>
              <div style="margin: 5px; cursor: pointer; display: inline-block;" @click="takeScreenshot('sunburstChart', 5, 'download')"><img style="width: 50px;" :src="'/share/download_clr.png'" /></div>
            </div>
        </div>
      </div>

      <div id="chartTooltip" v-if="hoveredItem && hoveredItem.label" :style="{ left: (hoveredItemPos.x+10)+'px', top: (hoveredItemPos.y+10)+'px' }"><b>{{hoveredItem.label}}</b><br/><span style="font-size:0.8em">{{hoveredItem.value}} {{ $t( hoveredItem.value==1?'impactPage.record':'impactPage.records') }}</span></div>

        
    </div>
    

      <div id="contactBar">
        <img  src="./assets/img/tortoise.png" class="contactIllustration" style="position: absolute; width: 180px; top: -125px; right: 170px;">
          <white-button label="CONTACT" icon="contact" />
      </div>

      <!-- <div class="sectionContainer" style="padding: 100px 0px; background-color: #FFF6F4; text-align: center;">
        <blog />
      </div> -->

      <div id="loadingBlocker" v-if="loadingShown">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#ffffff00;display:block;position: relative; top: 45%;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(30 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(60 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(90 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(120 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(150 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(180 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(210 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(240 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(270 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(300 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(330 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e86e59">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          </svg>
      </div>


    <div id="canvasCopy"></div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from "vuex";
//import VSelect from 'vuetify'
import ShareButton from './ShareButton.vue'
import Blog from './Blog.vue'
import WhiteButton from './WhiteButton.vue'
import HeaderBar from './HeaderBar.vue'
//import { VSelect } from 'vuetify/lib/framework';
import html2canvas from 'html2canvas';

export default {

  components: {  ShareButton, WhiteButton, HeaderBar, Blog },  
  name: "HelloWorld",
  props: {
    msg: String,
  },
  computed:{
      ...mapState([
      /* "publicPath",
      "categories", */
      "categoryColors",
    ]),
    /* selectedRegionIndex(){
      console.log("selectedRegionIndex");
      let id= this.selectedRegionId
      let index = 0;
      for (let i = 0; i < this.data_stats.sections.mostRecordsbyRegion.length; i++) {
        const element = this.data_stats.sections.mostRecordsbyRegion[i];
        if( element.id == id ){
          return i;
        }        
      }
      return index;
    } */
  },
  data(){
    return {
      sunburstSelectedItem: null, 
      hoveredItem: null, 
      hoveredItemPos: null, 
      data_stats: null,
      data_tree: null, 
      selectedRegionIndex: 0, 
      selectedSunburstRegionId: 0,
      regions: [],
      region_selected: null, 
      loadingShown: false
    }
  },
  methods: {
    getSunburstBreadcrumbs(){
      if( this.sunburstSelectedItem ){
        let selectedId = this.sunburstSelectedItem.id;
        let parentId = null;
        let results = [ {id:selectedId, name:this.sunburstSelectedItem.label} ];
        let item = this.sunburstSelectedItem;
        while( item ){
          item = this.getSunburstItemParent(item);
          if( item ){
            results.push( {id:item.id, name:item.label} )
          }
        }
        console.log( "breadcrumbs: ", results )
        if( results.length > 1)
          return results.reverse();
        else
          return [];
      }
    },
    getSunburstItemParent( item ){
      let itemParentId = item.parent;
      let parent_item_index = this.data_tree.ids.indexOf( itemParentId );
      if( parent_item_index >= 0 ){
        return {id:this.data_tree.ids[parent_item_index], label:this.data_tree["labels_"+this.$i18n.locale][parent_item_index], parent:this.data_tree.parents[parent_item_index]} 
      }else{
        return null;
      }
    },
    uploadPhoto(formData, website){
      console.log("uploadPhoto");

            /* axios.put( BASE_API_URL + path, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }) */
      axios
      .post('https://backend.projectyokkasoe.org/api/socialnetworksphoto', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            } )
      /* .get("https://backend.projectyokkasoe.org/api/regions")
      .then(result =>{
        console.log("loaded regions stats: ", result.data.results);
        this.regions = result.data.results;
        return axios.get('data/impact_stats.json');
      }) */
      .then(result =>{
        let share_id = result.data.id;
        console.log("pgotoupload data: ", result);
        let websiteUrl;
        if( website == "facebook" ){
          websiteUrl = "https://www.facebook.com/sharer/sharer.php?u=https://impact.projectyokkasoe.org/social/redirect/"+share_id+"?lang="+this.$i18n.locale;
        }
        else if( website == "twitter" ){
          websiteUrl = "https://twitter.com/intent/tweet?url=https://impact.projectyokkasoe.org/social/redirect/"+share_id+"?lang="+this.$i18n.locale;
        }
        console.log("share to url: ", websiteUrl );
        setTimeout( ()=>{
          this.loadingShown = false;
          window.open( websiteUrl );
        }, 1000 );
        
      })      
      .catch( err=>{
        console.error( "Error uploading photo: ", err );
        alert( "There was an error uploading the sharing image, please try again");
        this.loadingShown = false;
      });
    },
    regionSunburstChanged(event){
      console.log( "regionSunburstChanged(): ", this.selectedSunburstRegionId );
      this.selectedSunburstRegionId = event.target.value;
      this.loadSunburstData();
    },
    getSinceDate(){
      return this.data_stats.sinceDate;
    }, 
    updateChartTooltip(evt){
      this.hoveredItemPos = { x:evt.x, y:evt.y };
    }, 
    takeScreenshot(id, index, type){
      console.log("takeScreenshot ", id, type);
      this.loadingShown = true;
      html2canvas(document.querySelector("#"+id)).then(canvas => {
        let cnt = document.querySelector("#canvasCopy");
        cnt.innerHTML = "";
        cnt.appendChild(canvas);
        let photoData = canvas.toDataURL("image/png");
        if( type == 'download' ){
          let anchor = document.createElement("a");
          anchor.href = photoData;
          anchor.download = "yokkasoe.png";
          anchor.click();
          anchor.remove();
        }else{
          // alert("Pending implementation of server-side image storage");

          canvas.toBlob( (blob) => {
            // 
            let formData = new FormData();
            formData.append("photo", blob, 'filename.png');
            formData.append("post_index", index);
            this.uploadPhoto(formData, type);
            //
           /*  const formData = new FormData();
            formData.append('photo', blob, 'filename.png'); */
          });
        }
        //console.log("takeScreenshot 1", cnt, canvas );
      });
    }, 
    loadSunburstData( ){
      let apiUrl = "https://backend.projectyokkasoe.org/api/stats/impact_tree";
      
      if( this.selectedSunburstRegionId != "0" )
        apiUrl += "/" + this.selectedSunburstRegionId;
      axios
      .get( apiUrl )
      .then( result =>{
        console.log( "loaded data tree: ", result.data );
        let allColors = [ "#27AE60","#F2994A","#2F80ED", "#9B51E0", "#F2C94C"]
        let usedColors = [ "#ffffff" ];

        for (let i = 0; i < result.data.parents.length; i++) {
          const element = result.data.parents[i];
          if( element == '' ){
            result.data.parents[i] = ' ';
            let categoryColor = this.categoryColors[result.data.ids[i] ];
            if( categoryColor )
                usedColors.push( categoryColor.color ); //  allColors[i]
          }          
        } 
        result.data.ids.unshift(" ");
        result.data.labels_en.unshift(this.$t('impactPage.all'));
        result.data.labels_bu.unshift(this.$t('impactPage.all'));
        result.data.parents.unshift("");
        result.data.values.unshift(""); //this.data_stats.num_records

        this.data_tree = result.data;

        setTimeout(() => {
          let data = [{
            type: "sunburst",
            ids: this.data_tree.ids.map( (item)=>{ return item.toString() }),
            labels: this.data_tree["labels_"+this.$i18n.locale],
            values: this.data_tree.values,
            parents: this.data_tree.parents.map( (item)=>{ return item.toString() }),
            insidetextorientation: 'radial', 
            //outsidetextfont: {size: 20, color: "#377eb8"},
            leaf: {opacity: 0.4},
            marker: {line: {width: 0.5}, colors: usedColors },
            branchvalues: 'total', 
            hoverinfo: 'none'
          }];
          console.log( "DATA: ", data );

          let layout = {
            margin: {l: 10, r: 10, b: 10, t: 10},
            width: 800,
            height: 800, 
            //hovermode: false,
          };

          let chartContainer = document.getElementById("sunburstChart");
          chartContainer.innerHTML = "";
          window.Plotly.newPlot(chartContainer, data, layout, {modeBarButtonsToRemove:['toImage'], displaylogo: false});
          chartContainer.on('plotly_click', (data)=>{
            console.log( "sunburst click", this.sunburstSelectedItem == data.points[0], this.sunburstSelectedItem, data.points[0] );
            if( this.sunburstSelectedItem && this.sunburstSelectedItem.id == data.points[0].id ){
              let parent = this.getSunburstItemParent( data.points[0] );
              this.sunburstSelectedItem = parent;
            }else{
              this.sunburstSelectedItem = data.points[0];
            }
          });
          chartContainer.on('plotly_hover', (data)=>{
            console.log( "Hover: ", data );
            this.hoveredItem = data.points[0];
              this.hoveredItemPos = { x:data.event.x, y:data.event.y };
            document.addEventListener( "mousemove", this.updateChartTooltip );
            return false;
          });
          chartContainer.on('plotly_unhover', (data) => {
            console.log( "Out: ", data );
            this.hoveredItem = null;
            this.hoveredItemPos = null;
            document.removeEventListener( "mousemove", this.updateChartTooltip );
            return false;
          });
        }, 2000);
      })
      .catch( err=>{
        console.error( "Error loading data: ", err )
      });

    }
  },
  mounted() {
    window.app = this;
    console.log("App mounted");
    axios
      .get('https://backend.projectyokkasoe.org/api/stats/impact_stats')
      /* .get("https://backend.projectyokkasoe.org/api/regions")
      .then(result =>{
        console.log("loaded regions stats: ", result.data.results);
        this.regions = result.data.results;
        return axios.get('data/impact_stats.json');
      }) */
      .then(result =>{
        console.log("loaded data stats: ", result.data);
        this.data_stats = result.data;
        this.loadSunburstData();
      })      
      .catch( err=>{
        console.error( "Error loading data: ", err )
      });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.headerTitle{
    line-height: 65px;
}

#chartTooltip{
  color: #333333;
  position: fixed;
  background-color: #ffffff;
  padding: 5px 10px;
  border: #ccc;
  pointer-events: none;;
  box-shadow: 3px 3px 12px #00000060;
  font-size: 22px;
}

#contactBar{
      background-color: #E86E59;
    width: 100%;
    position: relative;
    /* height: 100px; */
    margin-top: 70px;
    padding: 70px 0px 70px 240px;
    box-sizing: border-box;
}

.appDiv{
  font-family: Odibee Sans;
  font-size: 40px;
  padding-top: 240px;
}

.topBar{
  background-color: #E86E59;
  position: fixed;
  left: 0px;
   top: 0px;
   right: 0px;
   height: 100px;
}

.mainContainer{
  position: relative;
  
  margin: auto;
  width: max-content;
}

.sectionContainer{
  position: relative;
  padding: 60px 0px;
}

.posterContainer{
  position: relative;
  display: inline-block;
  text-transform: uppercase;
}
.posterSide{
  position: relative;
    display: inline-block;
    padding: 20px;
    top: -15px;
}

.posterBackground{
  /* position: absolute; */
  top: 0px;
  left: 0px;
  width: 540px;
  height: 540px;
}

.posterText{
  position: absolute;
  font-size: 48px;
  top: 22px;
  left: 25px;
  color: #ffffff;
  width: max-content;
}

.rank1Item{
  font-size: 40px;
  position: absolute;
  /* color: rgb(255, 0, 0); */
  left: 39px;
  width: max-content;
  line-height: 21px;
}

.rank1ItemBurmese{
  font-size: 21px;
  line-height: 33px;
}

.rank2Item{
  font-size: 40px;
  position: absolute;
  /* color: rgb(255, 0, 0); */
  left: 265px;
  width: max-content;
  line-height: 21px;
}

.rank2ItemBurmese{
  font-size: 21px;
  line-height: 33px;
}

.shareLabel{
  font-size: 20px;
  font-family: Ubuntu Mono;
  margin-bottom: 10px;
}

.categoryButton{
    background-color: #ffd480;
    height: 80px;
    width: 100%;
    border-radius: 10px;
    padding: 15px 10px;
    box-sizing: border-box;
    text-align: center;
    background-size: cover;
}

.commonDropdown{
    border: 1px solid #ceb6b2;
    border-radius: 10px;
    padding: 15px 10px;
    font-family: Ubuntu;
    color: #423735;
    font-size: 16px;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: auto;
   
}

#canvasCopy{
  position: fixed;
  top: 10000px;
  left: 10000px;
  /* background-color: #ff0000; */
  min-width: 200px;
  min-height: 200px;
}

#loadingBlocker{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #ffffffDD;
  z-index: 1000;
  /* display: none; */
}

.animalsHeader{
  position: absolute;
  width: 290px;
  top: -150px;
  right: 90px;
}

.homeTownDropdown{
  width: 240px;
  margin: 50px 0px 120px;
}

.wildLifeSuperContainer{
  display:inline-block;
  margin-right: 30px;
} 

  .posterContainerDouble{
    display: block;
  }
.top3Subtitle{
    font-size: 48px; text-transform: none; margin-bottom: 100px;
  }
  .posterSide.topRecordsRegionSelect{
    position: absolute;
    top: 39px;
    left: 540px;
  }
  .topRegionsTitleEnglish{
    font-size: 45px; 
    color: #E86E59; 
    width: 250px; 
    line-height: 40px;
  }
  .topRegionsTitleBurmese{
    font-size: 20px;
    color: rgb(232, 110, 89);
    width: 290px;
    line-height: 47px;
    top: -16px;
    position: relative;
  }
  .highRiskAreasTitleEnglish{
    font-size: 40px; color: #E86E59; width: 250px; line-height: 40px; position: absolute; top: 145px; left: 245px;
  }
  .highRiskAreasTitleBurmese{
    font-size: 27px;
    color: rgb(232, 110, 89);
    width: 250px;
    line-height: 40px;
    position: absolute;
    top: 120px;
    left: 245px;
  }

  .sunBurstDropdown{
    position: absolute;
    top: 0px;
    right: 70px;
  }

  .sunburstDropdownAndShare{
    position: absolute; top: 0px; right: 70px;
  }
  .sunburstSection{
    padding: 0px;
  }

  .posterFooterText{
    position: absolute;
    right: 14px;
    bottom: 37px;
    font-size: 11px;
    color: #FFF3E4;
    width: 349px;
    text-align: right;
    line-height: 20px;
  }

@media (max-width: 1118px) {
  .mainContainer{
    transform: scale(0.75);
    transform-origin: top left;
    top: -20px;
    padding-left: 20px;
  }
  .sunburstSection{
    padding: 0px;
    margin-bottom: -1000px;
  }
}
@media (max-width: 900px) {
  .mainContainer{
    transform: scale(0.6);
    transform-origin: top left;
    top: -20px;
    padding-left: 20px;
  }
  .sunburstSection{
    padding: 0px;
    margin-bottom: -1700px;
  }
}

@media only screen and (max-width: 425px) {
  .mainContainer{
    width: 137%;
    padding: 10px;
    transform: scale(0.729);
    transform-origin: top left;
    top: -170px;
  }

  .homeTownDropdown {
    margin: 10px 0px 50px;
    transform: scale(1.5);
    transform-origin: top left;
  }

  #sunburstChart{
    width: 800px;
    height: 800px;
    transform-origin: top left;
    transform: scale(0.7);
    top: 100px;
    position: relative;
  }
  .animalsHeader{
    position: absolute;
    width: 290px;
    top: -150px;
    right: 90px;
  }
  .animalsHeader{
    display: none;
  }
  .posterSide[data-v-5ed14902] {
      display: inline-block;
      padding: 10px 0px;
          width: 541px;
  }
  .shareButton[data-v-5ed59008] {
    width: 31%;
    display: inline-block;
    margin-right: 1px;
  }


  .shareLabel{
    text-align: center;
    display: none;
  }
  .wildLifeSuperContainer{
    display:inline-block;
    margin-right: 0px;
    margin-top: 120px;
  } 

  .top3Subtitle{
    font-size: 48px; text-transform: none; margin-bottom: -100px;
  }

  .posterSide.topRecordsRegionSelect{
    position: relative;
    top: 0px;
    left: 0px;
  }


  .sunburstShare{
    position: absolute;
    top: 640px;
    right: 0px;
  }

  .sunburstDropdownAndShare{
    position: absolute;
    top: 0px;
    right: 20px;
  }

  .sunburstSection{
    padding: 0px;
    margin-bottom: -1740px;
  }

  #contactBar{    
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactIllustration{
    display: none;
  }


}

@media only screen and (max-width: 390px) {
  .mainContainer{
    width: 137%;
    transform: scale(0.67);
    transform-origin: top left;
  }

  .homeTownDropdown {
    margin: 10px 0px 50px;
    transform: scale(1.5);
    transform-origin: top left;
  }

  #sunburstChart{
    transform: scale(0.67);
    position: relative;
  }

  .shareButton{
    width: 33%;
    display: inline-block;
    margin-right: 1px;
    padding: 0px;
  }
}
</style>
