<template>
    <transition name="slideDown"> 
        <div id="mainMenu" class="secondPanel" v-show="mainMenuShown">
            <div class="panelContents">
            <div class="listItem" @click="showAboutPanel">
                <img :src="`${publicPath}img/customicons/Info.png`" />
                <p>{{ $t('menu.about') }}</p>
            </div>
            <div class="listItem" @click="showAddTrackPanel()">
                <img :src="`${publicPath}img/customicons/Eye.png`" />
                <p>{{ $t('addRecord.add-a-record') }}</p>
            </div>
            <div class="listItem" @click="showDraftTrackslist">
                <img :src="`${publicPath}img/customicons/NotePencil.png`"/>
                <p>{{ $t('draftsList.draft-records') }}</p>
            </div>
            <div class="listItem">
                <img :src="`${publicPath}img/customicons/SlidersHorizontal.png`" />
                <p>{{ $t('menu.change-language') }}</p>
                <div>
                <div class="secondaryButton" style="display: inline-block; width: 48%; text-align: center; margin-bottom: 0px; margin-top: 10px; margin-right: 5px;" @click="changeLanguage('en')">English</div>
                <div class="secondaryButton" style="display: inline-block; width: 48%; text-align: center; margin-bottom: 0px; margin-top: 10px; margin-left: 5px;" @click="changeLanguage('bu')">မြန်မာ</div>
                </div>
            </div>
            <div class="listItem" @click="showHowtouse">
                <img :src="`${publicPath}img/customicons/Question.png`" />
                <p>{{ $t('menu.faq') }}</p>
            </div>
            <!-- <div class="listItem" @click="goToBlog">
                <img :src="`${publicPath}img/customicons/Blog.png`" />
                <p>{{ $t('menu.blog') }}</p> 
            </div> -->
            </div>
        </div>
        </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    mainMenuShown: Boolean,
    showAboutPanel: Function,
    showAddTrackPanel: Function,
    showDraftTrackslist: Function, 
    showHowtouse: Function, 
    goToBlog: Function, 
    changeLanguage: Function, 
  },
  components: {},
  computed: {
    ...mapState(["publicPath"]),
  },
};
</script>

<style scoped>
#mainMenu {
  bottom: auto;
  top: 50px;
  height: auto;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
</style>