<template>
    <div>
        <div id="faqSection">
            <div class="homeSectionTitle" style="color: #E86E59;">{{ $t('menu.faq') }}</div>
            <img :src="`${publicPath}img/assets/faq_illustration.png`" style="position: absolute; top: 0px; right: 0px; max-width: 280px; width: 30%;"/>
        </div>

        <div class="homeFaqSection">
            <div class="homeFaqContents">
            <faq-element v-for="faq in $t('faqs')" :key="faq.id" :faqObject="faq" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import FaqElement from '../../components/FaqElement.vue'
export default {
    components: {
        FaqElement, 
    },
    computed: {    
        ...mapState( ['publicPath'])
    }, 
    
}
</script>

<style scoped>
#faqSection {
  display: none;
}

/* desktop */

@media only screen and (min-width: 416px) {
    #faqSection {
        display: block;
        background-color: #fffcf9;
        text-align: center;
        padding: 70px 0px;
        color: #e86e59;
        position: relative;
    }
}

 

</style>