import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import smoothscroll from 'smoothscroll-polyfill';
import i18n from './i18n'
smoothscroll.polyfill();

Vue.config.productionTip = false

const defaultLocale = localStorage.getItem("locale");
if( defaultLocale ){
  i18n.locale = defaultLocale;
}
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
