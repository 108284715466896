<template>
    <div id="bottombar" v-show="!trackDetailsShown && !addTrackPanelShown">
        <div class="bottombar-button" style="position: relative; top: 8px;" @click="toggleFilters">
        <img :src="`${publicPath}img/customicons/Funnel.png`" />
        </div>
        
        <div class="bottombar-button" style="background-color: #E86E59; display: flex; justify-content: center; align-items: center;" @click="showAddTrackPanel()">
        <div style="    position: relative; top: -33px; background-color: #E86E59; width: 40px; padding: 13px; box-sizing: content-box; border-radius: 50px;">
            <img :src="`${publicPath}img/customicons/track point.png`" style="height: 40px;"/>        
        </div>
        <div style="    display: block; width: max-content; position: absolute; bottom: 7px; font-size: 17px; font-family: 'Odibee Sans'; color: #FFFCF9;">{{ $t('addRecord.add-a-record') }}</div>
        </div>
        <div class="bottombar-button" style="position: relative; top: 8px;" @click="showTracksList">
            <img :src="`${publicPath}img/customicons/ListDashes.png`" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    trackDetailsShown: Boolean,
    addTrackPanelShown: Boolean,
    toggleFilters: Function,
    showAddTrackPanel: Function,
    showTracksList: Function
  },
  components: {},
  computed: {
    ...mapState(["publicPath"]),
  },
};
</script>

<style scoped>
#bottombar {
  background-color: #ffc352;
  position: fixed;
  bottom: 0px;
  height: 50px;
  left: 0px;
  right: 0px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.bottombar-button {
  text-align: center;
}

/* desktop */
@media only screen and (min-width: 416px) {
  #bottombar {
    display: none;
  }
}
</style>