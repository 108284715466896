<template>
  <div id="nav" style="overflow: hidden">
    <img
      id="logo"
      @click="showWelcomeScreen"
      style="cursor: pointer"
      :src="`${publicPath}img/customicons/logo.png`"
    />
    <img
      id="burger"
      :src="`${publicPath}img/customicons/burger.png`"
      @click="toggleMainMenu"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    showWelcomeScreen: Function,
    toggleMainMenu: Function,
  },
  components: {},
  computed: {
    ...mapState(["publicPath"]),
  },
};
</script>

<style scoped>
#logo {
  height: 50px;
  left: 0px;
  position: absolute;
}

#burger {
  position: fixed;
  right: 15px;
  top: 15px;
  height: 20px;
}

#nav {
  padding: 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  background-color: #e86e59;
  z-index: 1;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


/* desktop */
@media only screen and (min-width: 416px) {
  #nav{
    display: none;
  }
}

</style>