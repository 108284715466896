<template>
    <div>
      <navigation-bar
        :showWelcomeScreen="showWelcomeScreen"
        :toggleMainMenu="toggleMainMenu"
      ></navigation-bar>
      <landing-section
        :scrollToSection="scrollToSection"
        :changeLanguage="changeLanguage"
      />
      <stats-section />

      <div id="viewContainer">
        <!-- <div id="map" class="mapContainer"> -->
        <MglMap
          accessToken="pk.eyJ1IjoicHJvdG96b28iLCJhIjoiY2tyM3IxNjluMW1wNjJvbW5lZ2N5cnM1ZCJ9.CmiTelXq_QVQKbKQozkeNw"
          :class="{
            reducedMap:
              filtersShown ||
              filterDetailsShown ||
              trackslistShown ||
              trackDetailsShown ||
              addTrackPanelShown ||
              draftTrackslistShown,
          }"
          ref="mainMap"
          :zoom="4.56"
          :minZoom="4"
          :maxZoom="14"
          :fadeDuration="0"
          :center="mapCenter"
          :mapStyle.sync="mapStyle"
          @load="onMapLoaded"
          @zoom="mapZoomHandler"
          @dragstart="mapDragHandler"
        >
          <!-- <MglMarker :coordinates="[96.6174319, 21.4960471]">
        <div slot="marker" class="customMarker"></div>
        </MglMarker> -->
          <MglMarker
            v-if="!addTrackPanelShown"
            v-for="track in individuals"
            :key="'tr_' + track.id"
            :coordinates="track.geo_location.coordinates"
            :offset="[0, -24]"
            @click="setSelectedTrack(track)"
          >
            <div
              slot="marker"
              class="customMarker"
              :style="{
                'border-color': getCategoryColor(track.category_detail.id),
                'background-image':
                  'url(' +
                  (track.photo ||
                    publicPath + 'img/dummy/listitemDefault.jpeg') +
                  ')',
              }"
            >
              <div
                class="markerSpike"
                :style="{
                  'background-color': getCategoryColor(
                    track.category_detail.id
                  ),
                }"
              />
            </div>
          </MglMarker>

          <MglGeojsonLayer
            :sourceId="tracksToGeoJson().data.id"
            :source="tracksToGeoJson()"
            cluster="true"
            layerId="myLayerId"
            :layer="clusterLayer"
            @click="clusterClicked"
          />
          <MglGeojsonLayer
            :sourceId="tracksToGeoJson().data.id"
            :source="tracksToGeoJson()"
            cluster="true"
            layerId="myLayerId2"
            :layer="clusterTextLayer"
          />
          <MglGeolocateControl position="top-right" v-if="mapDragIsEnabled" />
          <MglNavigationControl position="top-right" :showCompass="false" />

          <img
            class="addTrackTarget"
            src="./assets/img/customicons/track_point.png"
            v-if="addTrackPanelShown"
          />
        </MglMap>

        <img
          class="mapResetFilterBtn"
          :src="`${publicPath}img/customicons/FilterOut.png`"
          @click="resetFilters"
          v-if="currentFilterType && currentFilterItem"
        />

        <div id="mainPanel" :class="{reducedPanel:isDesktop&&addTrackPanelShown&&$refs.addTrackPanel.mapTooltipShown}">
          <transition name="slideUp">
            <div
              id="howtousePanel"
              class="secondPanel fullPanel"
              v-show="howtouseShown"
            >
              <div
                :class="{
                  panelTitle: true,
                  panelTitleBurmese: $i18n.locale == 'bu',
                }"
              >
                <img
                  :src="`${publicPath}img/customicons/Question.png`"
                  style="height: 28px"
                />
                <div class="">{{ $t("menu.faq") }}</div>
                <div class="buttons" @click="howtouseShown=false">
                  <img :src="`${publicPath}img/customicons/close.png`" />
                </div>
              </div>
              <div class="sectionDivider" style="margin-top: 5px"></div>
              <div class="panelContents">
                <div class="homeFaqContents" style="padding: 0px 10px 100px">
                  <faq-element
                    v-for="faq in $t('faqs')"
                    :key="faq.id"
                    :faqObject="faq"
                    style="line-height: 25px; padding: 20px 0px"
                  />
                </div>
              </div>
            </div>
          </transition>

          <transition name="slideUp">
            <div
              id="aboutPanel"
              class="secondPanel fullPanel"
              v-show="aboutShown"
            >
              <div
                :class="{
                  panelTitle: true,
                  panelTitleBurmese: $i18n.locale == 'bu',
                }"
              >
                <img
                  :src="`${publicPath}img/customicons/Info.png`"
                  style="height: 28px"
                />
                <div class="">{{ $t("menu.about") }}</div>
                <div class="buttons" @click="hideAboutPanel">
                  <img :src="`${publicPath}img/customicons/close.png`" />
                </div>
              </div>
              <div class="sectionDivider" style="margin-top: 5px"></div>
              <div class="panelContents">
                <div style="padding: 10px 10px 100px">

              <div v-if="!isDesktop && $i18n.locale == 'bu'" style="position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; margin-bottom: 20px;">
                <iframe 
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                  width="560" height="315" src="https://www.youtube.com/embed/I0XVp4t2Me0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
                  <div
                    class="homeSectionSubtitle aboutSubtitle"
                    style="margin-bottom: 40px"
                    v-html="$t('about.text0')"
                  ></div>
                  <div
                    style="font-family: 'Ubuntu'; font-size: 16px"
                    v-html="$t('about.text1')"
                  ></div>
                </div>
              </div>
            </div>
          </transition>

          <!-- <transition name="slideUp"> -->
            <div id="filtersPanel" class="secondPanel" v-show="filtersShown">
              <div
                :class="{
                  panelTitle: true,
                  panelTitleBurmese: $i18n.locale == 'bu',
                }"
              >
                <img
                  :src="`${publicPath}img/customicons/Funnel.png`"
                  style="height: 28px"
                />
                <div class="">{{ $t("filters.title") }}</div>
                <div class="buttons" @click="hideFilters">
                  <img :src="`${publicPath}img/customicons/close.png`" />
                </div>
              </div>
              <div class="panelContents">
                <!-- <transition name="fade"> -->
                <div
                  class="filtersMenu"
                  v-show="currentFilterScreen == 'primary'"
                >
                  <div
                    :class="{
                      listItem: true,
                      listItemBurmese: $i18n.locale == 'bu',
                    }"
                    @click="setFilterType('period')"
                  >
                    {{ $t("filters.by-period") }}
                    <img
                      :src="
                        currentFilterType == 'period' && currentFilterItem
                          ? publicPath + 'img/customicons/CheckCircle.png'
                          : publicPath + 'img/customicons/DotsThreeCircle.png'
                      "
                      class="threeDotsIcon"
                    />
                  </div>
                  <div
                    :class="{
                      listItem: true,
                      listItemBurmese: $i18n.locale == 'bu',
                    }"
                    @click="setFilterType('category')"
                  >
                    {{ $t("filters.by-category") }}
                    <img
                      :src="
                        currentFilterType == 'category' && currentFilterItem
                          ? publicPath + 'img/customicons/CheckCircle.png'
                          : publicPath + 'img/customicons/DotsThreeCircle.png'
                      "
                      class="threeDotsIcon"
                    />
                  </div>
                  <div
                    :class="{
                      listItem: true,
                      listItemBurmese: $i18n.locale == 'bu',
                    }"
                    @click="setFilterType('state')"
                  >
                    {{ $t("filters.by-state-of-conservation") }}
                    <img
                      :src="
                        currentFilterType == 'state' && currentFilterItem
                          ? publicPath + 'img/customicons/CheckCircle.png'
                          : publicPath + 'img/customicons/DotsThreeCircle.png'
                      "
                      class="threeDotsIcon"
                    />
                  </div>
                  <div
                    class="orangeButton"
                    @click="resetFilters"
                    v-if="currentFilterType && currentFilterItem"
                  >
                    <img :src="`${publicPath}img/customicons/FunnelOff.png`" />
                  </div>
                </div>
                
                <div
                  class="filtersSubmenu"
                  v-show="currentFilterScreen == 'secondary'"
                >
                  <div
                    class="secondaryButton"
                    @click="currentFilterScreen = 'primary'"
                  >
                    &lt; {{ $t("filters.back") }}
                  </div>

                  <div v-if="currentFilterType == 'period'">
                    <div
                      class=""
                      style="color: #423735; font-family: 'Ubuntu Mono'"
                    >
                      {{ $t("filters.by-period") }}
                    </div>
                    <div class="sectionDivider" style="margin-top: 10px"></div>
                    <div>
                      {{ $t("filters.from") }}<br />
                      <input
                        class="defaultInput"
                        type="date"
                        v-model="currentFilterItem.start"
                        @change="filterDateChanged"
                        style="width: min-content; width: 220px"
                      />
                    </div>
                    <div>
                      {{ $t("filters.until") }}<br />
                      <input
                        class="defaultInput"
                        type="date"
                        v-model="currentFilterItem.end"
                        @change="filterDateChanged"
                        style="width: min-content; width: 220px"
                      />
                    </div>
                    <div>
                      <button
                        class="defaultButton"
                        @click="confirmFilterDateChanged"
                        style="margin: 15px 0px -20px"
                      >
                        {{ $t("filters.confirm-dates") }}
                      </button>
                    </div>
                  </div>
                  <div v-if="currentFilterType == 'category'">
                    <div
                      class=""
                      style="color: #423735; font-family: 'Ubuntu Mono'"
                    >
                      {{ $t("filters.by-category") }}
                    </div>
                    <div class="sectionDivider" style="margin-top: 10px"></div>
                    <div
                      class="listItem"
                      v-for="category in categories"
                      :key="category.id"
                      @click="setFilterItem(category)"
                    >
                      <span
                        class="typeBullet"
                        :style="{
                          'background-color': getCategoryColor(category.id),
                        }"
                      />{{
                        $i18n.locale == "bu" ? category.name_bir : category.name
                      }}
                      <img
                        :src="`${publicPath}img/customicons/CheckCircle.png`"
                        style="height: 40px; position: absolute; right: 10px"
                        v-if="
                          currentFilterType == 'category' &&
                          currentFilterItem == category
                        "
                      />
                    </div>
                  </div>
                  <div v-if="currentFilterType == 'state'">
                    <div
                      class=""
                      style="color: #423735; font-family: 'Ubuntu Mono'"
                    >
                      {{ $t("filters.by-state-of-conservation") }}
                    </div>
                    <div class="sectionDivider" style="margin-top: 10px"></div>
                    <div
                      class="listItem"
                      v-for="impact, $index in impacts"
                      :key="impact.id"
                      @click="setFilterItem(impact)"
                    >
                      {{ $t("impacts.label"+($index+1 )) }}
                      <img
                        :src="`${publicPath}img/customicons/CheckCircle.png`"
                        style="height: 40px; position: absolute; right: 10px"
                        v-if="
                          currentFilterType == 'state' &&
                          currentFilterItem == impact
                        "
                      />
                    </div>
                  </div>
                  <div style="margin: 100px 0px"></div>
                </div>
                
              </div>
            </div>
          <!-- </transition> -->

          <transition name="fade">
            <div
              id="filterDetailsPanel"
              class="secondPanel"
              v-show="filterDetailsShown"
            >
              <div
                :class="{
                  panelTitle: true,
                  panelTitleBurmese: $i18n.locale == 'bu',
                }"
              >
                <img
                  :src="`${publicPath}img/customicons/Funnel.png`"
                  style="height: 28px"
                />
                <div class="">{{ $t("filters.title") }}</div>
                <div class="buttons" @click="hideFilterDetails">
                  <img :src="`${publicPath}img/customicons/close.png`" />
                </div>
              </div>
              <div class="panelContents">
                <div class="trackTitle">{{ $t("filters.by-category") }}</div>
                <div class="listItem">
                  {{ $t("filters.color-by-category") }}
                </div>
                <div class="sectionDivider"></div>

                <div class="trackTitle">{{ $t("filters.select") }}</div>
                <div
                  class="listItem"
                  v-for="category in categories"
                  :key="category.id"
                >
                  <span
                    class="typeBullet"
                    :style="{
                      'background-color': getCategoryColor(category.id),
                    }"
                  />{{ category.name }}
                </div>
                <div style="margin: 100px 0px"></div>
              </div>
            </div>
          </transition>

          <!-- <transition name="slideUp"> -->
            <add-track
              id="addTrackPanel"
              ref="addTrackPanel"
              :getMapCenter="getMapCenter"
              @locationIsSet="disableMapDragAndZoom"
              @closeClicked="hideAddTrackPanel"
              v-show="addTrackPanelShown"
              :class="{reducedPanelContents:isDesktop&&addTrackPanelShown&&$refs.addTrackPanel.mapTooltipShown}"
            >
            </add-track>
          <!-- </transition>

          <transition name="slideUp"> -->
            <div
              id="tracksList"
              class="secondPanel"
              v-show="
                trackslistShown ||
                (isDesktop &&
                  !filtersShown &&
                  !filterDetailsShown &&
                  !filtersShown &&
                  !trackDetailsShown &&
                  !addTrackPanelShown)
              "
            >
              <div
                :class="{
                  panelTitle: true,
                  panelTitleBurmese: $i18n.locale == 'bu',
                }"
              >
                <img
                  :src="`${publicPath}img/customicons/ListDashes.png`"
                  style="height: 28px"
                />
                <div class="">{{ $t("tracksList.records") }}</div>
                <div class="buttons" v-if="!isDesktop">
                  <img
                    :src="`${publicPath}img/customicons/close.png`"
                    @click="hideTrackslist"
                  />
                </div>
              </div>
              <div class="panelContents">
                <div
                  class="tracklistItem"
                  v-for="track in presentedTracks"
                  :key="track.id"
                  @click="setSelectedTrack(track)"
                >
                  <div
                    class="trackPhoto"
                    :style="{
                      'background-image':
                        'url(' +
                        (track.photo_thumbnail ||
                          track.photo ||
                          publicPath + 'img/dummy/listitemDefault.jpeg') +
                        ')',
                    }"
                  ></div>
                  <div style="position: absolute; top: 5px; left: 130px">
                    <div
                      class="tracklistItemTitle"
                      style="color: #e86e59; font-size: 16px"
                    >
                      <span
                        class="typeBullet"
                        :style="{
                          'background-color': getCategoryColor(
                            track.category_detail.id
                          ),
                        }"
                      />{{
                        track.category_detail[
                          $i18n.locale == "bu" ? "name_bir" : "name"
                        ]
                      }}
                    </div>
                    <div
                      class="tracklistItemDate"
                      style="font-size: 14px; margin-top: 2px"
                    >
                      {{ track.created_date.split("T")[0] }}
                    </div>
                    <div
                      class="tracklistItemTags"
                      style="margin-top: 6px; font-size: 12px"
                      v-if="track.region_selected_detail"
                    >
                      {{
                        track.region_selected_detail[
                          $i18n.locale == "bu" ? "name" : "extra_name"
                        ]
                      }}
                    </div>
                  </div>
                </div>
                <div style="margin: 80px 0px"></div>
              </div>
              <bottom-bar-desktop 
                :trackDetailsShown="trackDetailsShown"
                :addTrackPanelShown="addTrackPanelShown"
                :toggleFilters="toggleFilters"
                :showAddTrackPanel="showAddTrackPanel"
                :showTracksList="showTracksList">
              </bottom-bar-desktop>
              <!-- <div
                :class="{
                  filterDesktopButton: true,
                  filterDesktopButtonActive: currentFilterItem,
                }"
                @click="toggleFilters"
              >
                <div style="display: inline-block">
                  <img
                    src="./assets/img/customicons/funnel_off.png"
                    style="height: 28px"
                    v-if="!currentFilterItem"
                  />
                  <img
                    src="./assets/img/customicons/funnel_on.png"
                    style="height: 28px"
                    v-if="currentFilterItem"
                  />
                </div>
                <div
                  style="
                    display: inline-block;
                    position: relative;
                    top: -6px;
                    left: 5px;
                  "
                >
                  <span v-if="!currentFilterItem">{{
                    $t("tracksList.filter-map-results")
                  }}</span>
                  <span v-if="currentFilterItem">{{
                    $t("tracksList.change-filter")
                  }}</span>
                </div>
              </div> -->
            </div>
          <!-- </transition> -->

          <div
            id="draftTracksList"
            class="secondPanel"
            v-show="draftTrackslistShown"
          >
            <div
              :class="{
                panelTitle: true,
                panelTitleBurmese: $i18n.locale == 'bu',
              }"
            >
              <img
                :src="`${publicPath}img/customicons/NotePencil.png`"
                style="height: 28px"
              />
              <div class="">{{ $t("draftsList.draft-records") }}</div>
              <div class="buttons">
                <img
                  :src="`${publicPath}img/customicons/close.png`"
                  @click="hideDraftTrackslist"
                />
              </div>
            </div>
            <div class="panelContents">
              <div
                class="tracklistItem"
                v-for="(track, index) in draftTracks"
                :key="index"
                @click="reopenDraft(track, index)"
              >
                <img
                  :src="`${publicPath}img/dummy/listitemDefault.jpeg`"
                  style="height: 90px"
                />
                <img
                  :src="`${publicPath}img/customicons/trashbin.png`"
                  style="height: 28px; position: absolute; right: 5px; top: 5px"
                  @click="deleteDraft($event, index)"
                />
                <div style="position: absolute; top: 5px; left: 130px">
                  <div
                    class="tracklistItemTitle"
                    style="color: #e86e59; font-size: 16px"
                  >
                    {{ $t("draftsList.draft-record") }} {{ index + 1 }}
                  </div>
                  <div class="tracklistItemDate" style="font-size: 14px">
                    {{ track.created_date.split("T")[0] }}
                  </div>
                </div>
              </div>
              <div style="margin: 80px 0px"></div>
            </div>
          </div>

          <div
            id="trackDetails"
            class="secondPanel"
            v-if="trackDetailsShown && selectedTrack"
          >
            <div
              :class="{
                panelTitle: true,
                panelTitleBurmese: $i18n.locale == 'bu',
              }"
            >
              <img
                :src="`${publicPath}img/customicons/Eye.png`"
                style="height: 28px"
              />
              <div class="">{{ $t("trackDetails.record") }}</div>
              <div class="buttons">
                <img
                  :src="`${publicPath}img/customicons/close.png`"
                  @click="hideTrackDetails"
                />
              </div>
            </div>
            <div
              class="panelContents"
              style="position: absolute; left: 0px; right: 0px"
            >
              <div class="trackDetails">
                <div
                  class="trackDetailsPhotoThumb"
                  v-if="selectedTrack.photo"
                  :style="{
                    'background-image':
                      'url(' +
                      (selectedTrack.photo_thumbnail_medium ||
                        selectedTrack.photo ||
                        publicPath + 'img/dummy/listitemDefault.jpeg') +
                      ')',
                  }"
                  @click="showEnlargedImage"
                >
                  <img
                    :src="`${publicPath}img/customicons/zoom_image_icon.png`"
                    style="height: 32px"
                  />
                </div>
              </div>

              <div class="panelSubContents" style="">
                <div class="trackTitle">
                  {{
                    selectedTrack.category_detail[
                      $i18n.locale == "bu" ? "name_bir" : "name"
                    ]
                  }}
                </div>
                <div class="trackDate">
                  {{ selectedTrack.date.split("T")[0] }}
                </div>
                <div class="trackBreadcrumbs">
                  <span v-if="selectedTrack.category_detail">{{
                    selectedTrack.category_detail[
                      $i18n.locale == "bu" ? "name_bir" : "name"
                    ]
                  }}</span>
                  <span v-if="selectedTrack.category2_detail">
                    <v-icon class="breadcrumbDivider">mdi-arrow-right</v-icon>
                    {{
                      selectedTrack.category2_detail[
                        $i18n.locale == "bu" ? "name_bir" : "name"
                      ]
                    }}</span
                  >
                  <span v-if="selectedTrack.category3_detail">
                    <v-icon class="breadcrumbDivider">mdi-arrow-right</v-icon>
                    {{
                      selectedTrack.category3_detail[
                        $i18n.locale == "bu" ? "name_bir" : "name"
                      ]
                    }}</span
                  >
                  <span v-if="selectedTrack.category4_detail">
                    <v-icon class="breadcrumbDivider">mdi-arrow-right</v-icon>
                    {{
                      selectedTrack.category4_detail[
                        $i18n.locale == "bu" ? "name_bir" : "name"
                      ]
                    }}</span
                  >
                  <span v-if="selectedTrack.category5_detail">
                    <v-icon class="breadcrumbDivider">mdi-arrow-right</v-icon>
                    {{
                      selectedTrack.category5_detail[
                        $i18n.locale == "bu" ? "name_bir" : "name"
                      ]
                    }}</span
                  >
                  <span v-if="selectedTrack.category6_detail">
                    <v-icon class="breadcrumbDivider">mdi-arrow-right</v-icon>
                    {{
                      selectedTrack.category6_detail[
                        $i18n.locale == "bu" ? "name_bir" : "name"
                      ]
                    }}</span
                  >
                </div>
                <div class="sectionDivider"></div>
                <div
                  class="trackDetailsSectionTitle"
                  style="margin-bottom: 30px"
                >
                  {{ $t("trackDetails.impact-on-nature") }}
                </div>
                <img
                  :src="`${publicPath}img/customicons/placemark.png`"
                  :style="{
                    height: '28px',
                    left: '-10px',
                    position: 'relative',
                    'margin-left': getImpactSliderPosition(selectedTrack.scale),
                  }"
                />
                <div class="trackDetailsChart"></div>
                <div class="scaleTicks">
                  <div class="scaleTicksMarks" />
                  <div class="scaleTicksMarks" />
                  <div class="scaleTicksMarks" />
                  <div
                    class="scaleTicksMarks"
                    style="border-right: solid 1px #ceb6b2"
                  />
                </div>
                <div class="scaleLegend">
                  <div class="scaleLegendItem">{{ $t("scale.negative") }}</div>
                  <div class="scaleLegendItem" style="text-align: center">
                    {{ $t("scale.neutral") }}
                  </div>
                  <div class="scaleLegendItem" style="text-align: right">
                    {{ $t("scale.positive") }}
                  </div>
                </div>
                <div class="sectionDivider" style="margin-top: 60px"></div>
                <div
                  class="trackDetailsSectionTitle"
                  v-if="selectedTrack.region_selected_detail"
                >
                  {{ $t("trackDetails.location-name") }}
                </div>
                <div
                  class="trackDetailsSectionContent"
                  v-if="selectedTrack.region_selected_detail"
                >
                  {{
                    selectedTrack.region_selected_detail[
                      $i18n.locale == "bu" ? "name" : "extra_name"
                    ]
                  }}
                </div>
                <div
                  class="sectionDivider"
                  v-if="selectedTrack.region_selected_detail"
                ></div>
                <div
                  class="trackDetailsSectionTitle"
                  v-if="selectedTrack.area_monitored"
                  style="display: inline-block; margin-right: 10px"
                >
                  {{ $t("trackDetails.area-size") }}
                </div>
                <div
                  class="trackDetailsSectionContent"
                  v-if="selectedTrack.area_monitored"
                  style="display: inline-block"
                >
                  {{
                    selectedTrack.area_monitored
                      ? squareMetersToHectares(
                          selectedTrack.area_monitored
                            .split(".")
                            .join("")
                            .split(",")
                            .join("")
                        ) + " Ha"
                      : $t("generic.unknown")
                  }}
                </div>
                <div
                  class="sectionDivider"
                  v-if="selectedTrack.area_monitored"
                ></div>
                <div
                  class="trackDetailsSectionTitle"
                  v-if="selectedTrack.description"
                >
                  {{ $t("trackDetails.description") }}
                </div>
                <div
                  class="trackDetailsSectionContent"
                  v-if="selectedTrack.description"
                >
                  {{ selectedTrack.description }}
                </div>
                <div
                  class="sectionDivider"
                  v-if="selectedTrack.description"
                ></div>
                <div
                  class="trackDetailsSectionTitle"
                  v-if="selectedTrack.source"
                >
                  {{ $t("trackDetails.evidence-source") }}
                </div>
                <div
                  class="trackDetailsSectionContent"
                  v-if="selectedTrack.source"
                >
                  <a :href="selectedTrack.source" target="_blank">{{
                    selectedTrack.source
                  }}</a>
                </div>
                <div class="sectionDivider" v-if="selectedTrack.source"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <getting-involved-section />

      <impact-banner-section />

      <about-section />
      <faqs-section style="padding-bottom: 150px;"/>
      <!-- <div class="blogContainer" v-if="isDesktop">
          <blog />        
      </div> -->
      <bottom-bar
        :trackDetailsShown="trackDetailsShown"
        :addTrackPanelShown="addTrackPanelShown"
        :toggleFilters="toggleFilters"
        :showAddTrackPanel="showAddTrackPanel"
        :showTracksList="showTracksList"
      ></bottom-bar>
      <main-menu
        :mainMenuShown="mainMenuShown"
        :showAboutPanel="showAboutPanel"
        :showAddTrackPanel="showAddTrackPanel"
        :showDraftTrackslist="showDraftTrackslist"
        :showHowtouse="showHowtouse"
        :goToBlog="goToBlog" 
        :changeLanguage="changeLanguage"
      />

      <welcome-panel
        :isDesktop="isDesktop"
        :welcomeShown="welcomeShown"
        :hideWelcome="hideWelcome"
      ></welcome-panel>

      <transition name="fade">
        <div class="enlargedPhoto" v-if="enlargedPhotoShown">
          <pinch-zoom
            limitZoom="4"
            style="
              overflow: hidden;
              background-color: rgba(66, 55, 53, 0.96);
              height: 100%;
            "
          >
            <img
              :src="
                selectedTrack.photo ||
                publicPath + 'img/dummy/listitemDefault.jpeg'
              "
              alt=""
            />
          </pinch-zoom>
          <div
            style="position: fixed; top: 10px; right: 10px"
            @click="enlargedPhotoShown = false"
          >
            <img
              :src="`${publicPath}img/customicons/close.png`"
              style="width: 30px"
            />
          </div>
        </div>
      </transition>
    </div>
</template>



<script>
import AddTrack from "./components/AddTrack.vue";
import { mapState } from "vuex";
import Mapbox from "mapbox-gl";
import {
  MglMap,
  MglMarker,
  MglGeolocateControl,
  MglNavigationControl,
  MglGeojsonLayer,
} from "vue-mapbox";
import PinchZoom from "vue-pinch-zoom";

import NavigationBar from "./components/NavigationBar.vue";
import BottomBar from "./components/BottomBar.vue";
import BottomBarDesktop from "./components/BottomBarDesktop.vue";
import MainMenu from "./components/MainMenu.vue";
import WelcomePanel from "./components/WelcomePanel.vue";

import StatsSection from "./components/sections/StatsSection.vue";
import GettingInvolvedSection from "./components/sections/GettingInvolvedSection.vue";
import ImpactBannerSection from "./components/sections/ImpactBannerSection.vue";
import AboutSection from "./components/sections/AboutSection.vue";
import FaqsSection from "./components/sections/FaqsSection.vue";
import LandingSection from "./components/sections/LandingSection.vue";

import FaqElement from "./components/FaqElement.vue";
import utilsMixins from "./mixins/utilsMixins";

import Blog from './Blog.vue'

export default {
  components: {
    AddTrack,
    FaqElement,
    MglMap,
    MglMarker,
    MglGeolocateControl,
    MglNavigationControl,
    MglGeojsonLayer,
    PinchZoom,
    NavigationBar,
    BottomBar,
    BottomBarDesktop,  
    MainMenu,
    WelcomePanel,
    StatsSection,
    GettingInvolvedSection,
    ImpactBannerSection, 
    AboutSection,
    FaqsSection,
    LandingSection,
    Blog
  },
  mixins: [utilsMixins],
  data() {
    return {
      geojsonTracks: {},
      mapCenter: [95.96979948101125, 18.271469789869684],
      mapStyle: "mapbox://styles/mapbox/satellite-streets-v9",
      mainMenuShown: false,
      draftTrackslistShown: false,
      filtersShown: false,
      trackslistShown: false,
      welcomeShown: true,
      trackDetailsShown: false,
      filterDetailsShown: false,
      addTrackPanelShown: false,
      howtouseShown: false,
      aboutShown: false,
      enlargedPhotoShown: false,
      selectedTrack: null,
      currentFilterType: null,
      currentFilterItem: null,
      currentFilterScreen: "primary",
      filteredTracks: [],
      draftMode: false,
      individuals: [],      

      clusterLayer: {
        type: "circle",
        cluster: "true",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#E86E59",
          "circle-radius": 15,
        },
      },
      clusterTextLayer: {
        type: "symbol",
        cluster: "true",
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
          "text-offset": [0, -0.5],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#ffffff",
        },
      },
    };
  },
  computed: {
    mapDragIsEnabled() {
      return (
        !this.addTrackPanelShown ||
        (this.addTrackPanelShown && this.$refs.addTrackPanel.mapTooltipShown)
      );
    },
    presentedTracks() {
      this.computeIndividuals();
      return this.draftMode ? this.draftTracks : this.tracks;
    },
    isDesktop() {
      let viewportWidth = window.innerWidth;
      return viewportWidth >= 416;
    },
    totalRecords() {
      return this.tracks.length;
    },
    totalArea() {
      return 350;
    },
    conservationAverage() {
      let total = 0;
      this.tracks.forEach((track) => {
        total = total + track.scale;
      });
      return total / this.tracks.length;
    },
    ...mapState([
      "publicPath",
      "stats",
      "tracks",
      "draftTracks",
      "categories",
      "categoryColors",
      "impacts",
    ]),
  },

  methods: {
    /* --------------------------------------------------
                General utilities
    -------------------------------------------------- */

    scrollToSection(selector) {
      let el = document.querySelector(selector);
      if (el) el.scrollIntoView({ behavior: "smooth" });
    },


    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.mainMenuShown = false;
      localStorage.setItem("locale", locale);
    },

    
    getCategoryColor: function (categoryId) {
      return this.$store.getters.getCategoryColor(categoryId);
    },


    getUserGeolocation: function () {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          console.log("success with geolocation: ", res);
          this.map.flyTo({
            center: [res.coords.longitude, res.coords.latitude],
            zoom: 14,
          });
        },
        (err) => {
          console.log("error with geolocation:", err);
          // to-do: add better instructions, like this: https://m.envirate.net/help/geolocation/
        }
      );
    },


    sendGoogleAnalytics(event_name, event_category, event_label) {
      let params = {
        event_category: event_category,
      };
      if (event_label) {
        params.event_label = event_label;
      }
      window.gtag("event", event_name, params);
    },

    setSelectedTrack(track) {
      if (track.id) {
        this.$store.dispatch("fetchTrackDetails", {
          id: track.id,
          callbackFunction: (result) => {
            console.log("Track details: ", result);
            this.showTrackDetails();
            this.selectedTrack = result;
            setTimeout(() => {
              this.map.resize();
              this.map.flyTo({
                center: track.geo_location.coordinates,
                zoom: 10,
              });
            }, 500);
          },
        });
        // draft case
      } else {
        let draftIndex = -1;
        let i = 0;
        this.presentedTracks.forEach((draft) => {
          console.log(track.date);
          if (draft.date == track.date) {
            draftIndex = i;
          }
          i++;
        });
        if (draftIndex > -1) {
          track.draftIndex = draftIndex;
          this.showAddTrackPanel(track);
        }
      }
    },




    /* --------------------------------------------------
                Draft Tracks management
    -------------------------------------------------- */

    reopenDraft(draft, index) {
      console.log("reopenDraft(): ", draft, index);
      draft.draftIndex = index;
      this.showAddTrackPanel(draft);
    },


    deleteDraft(event, index) {
      console.log("deleteDraft(): ", index);
      event.stopPropagation();
      const userConfirms = confirm(this.$t("drafts.confirm-delete"));
      console.log("confirm result: ", userConfirms);
      if (userConfirms) {
        this.$store.dispatch("deleteDraftTrack", { draftIndex: index });
      }
    },    

    
    setDraftMode(trueFalse) {
      this.draftMode = trueFalse;
      setTimeout(() => {
        this.computeIndividuals();
      }, 500);
    },
  


    /* --------------------------------------------------
                Map interactions
    -------------------------------------------------- */

    clusterClicked(event) {
      const features = this.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ["myLayerId"],
      });
      const clusterId = features[0].properties.cluster_id;
      const point_count = features[0].properties.point_count;
      let clusterSource = this.map.getSource("tracksList");
      clusterSource.getClusterLeaves(
        clusterId,
        point_count,
        0,
        function (err, aFeatures) {
          console.log(
            "clusterClicked. Leaves:",
            err,
            point_count /* aFeatures */
          );
          /* aFeatures.forEach( (track)=>{
            console.log( "-- ", track.properties.model.id, track.properties.model.category, track.geometry.coordinates.join(","), track.properties.model.created_date)
          } ); */
        }
      );
      console.log("clusterClicked: ", clusterId, event, features);
    },


    mapZoomHandler(event) {
      console.log("mapZoomHandler()");
      this.computeIndividuals();
    },


    mapDragHandler(event) {
      console.log("mapDragHandler()");
    },


    computeIndividuals() {
      console.log("computeIndividuals()", this.map);
      if (this.map) {
        const features = this.map.querySourceFeatures("tracksList");
        console.log("mapZoomHandler 2 features()", features.length);
        let present_ids = {};
        let individuals = features.filter((feature) => {
          const props = feature.properties;
          let is_present = props.id && present_ids[props.id];
          present_ids[props.id] = true;
          return !props.cluster && props.name && !is_present;
        });
        let tracks = [];
        individuals.forEach((feature) => {
          const model = JSON.parse(feature.properties.model);
          tracks.push(model);
        });
        console.log("mapZoomHandler 2 tracks()", tracks.length);
        this.individuals = tracks;
      }
    },


    tracksToGeoJson() {
      let tracks = this.presentedTracks;
      let result = {
        type: "geojson",
        cluster: true,
        clusterMaxZoom: 6,
        clusterRadius: 20,
        data: { id: "tracksList", type: "FeatureCollection", features: [] },
      };
      tracks.forEach((track) => {
        let ob = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: track.geo_location.coordinates,
          },
          properties: {
            id: track.id,
            name: track.category_detail.name,
            model: track,
          },
        };
        result.data.features.push(ob);
      });
      this.geojsonTracks = result;
      return result;
    },


    getMapCenter() {
      const map = this.$refs.mainMap.map; // access to the JS map object, as it using it vanilla-style
      const position = map.getCenter();
      const coords = { lng: position.lng, lat: position.lat };
      return coords;
    },


    onMapLoaded(event) {
      // in component
      this.map = event.map;
      this.$store.map = event.map;
      this.map.touchZoomRotate.disableRotation();
      setTimeout(() => {
        this.computeIndividuals();
      }, 500);
    },

    resizeMap() {
      let isDesktop = this.isDesktop;
      console.log("resizeMap: ", isDesktop);
      if (this.isDesktop) {
        return;
      }
      setTimeout(() => {
        this.map.resize();
      }, 100);
    },

    
    disableMapDragAndZoom() {
      this.map.dragPan.disable();
      this.map.scrollZoom.disable();
    },
    


    /* --------------------------------------------------
              Track filters management
    -------------------------------------------------- */

    resetFilters() {
      this.currentFilterType = null;
      this.currentFilterItem = null;
      this.currentFilterScreen = "primary";
      let options = {
        successCallback: () => {
          setTimeout(() => {
            this.computeIndividuals();
          }, 500);
        },
      };
      this.hideFilters();
      this.$store.dispatch("fetchTracks", options);
    },


    setFilterType(type) {
      this.currentFilterType = type;
      this.currentFilterScreen = "secondary";
      if (this.currentFilterType == "period") {
        let now = new Date();
        let formattedDate =
          now.getFullYear() +
          "-" +
          (now.getMonth() + 1).toString().padStart(2, "0") + // Make sure the values have the right number of digits, otherwise vuetify's datepicker seems to fail
          "-" +
          now.getDate().toString().padStart(2, "0");
        this.currentFilterItem = { start: formattedDate, end: formattedDate };
      }
    },


    setFilterItem(item) {
      console.log("setFilterItem: ", item);
      if (
        (this.currentFilterType == "category" ||
          this.currentFilterType == "state") &&
        item == this.currentFilterItem
      )
        this.currentFilterItem = null;
      else this.currentFilterItem = item;
      let options = {
        successCallback: () => {
          setTimeout(() => {
            this.computeIndividuals();
          }, 500);
        },
      };
      if (this.currentFilterType == "category" && this.currentFilterItem) {
        options.category = this.currentFilterItem.id;
      } else if (this.currentFilterType == "state" && this.currentFilterItem) {
        options.scale = this.currentFilterItem.id;
      }
      this.$store.dispatch("fetchTracks", options);
    },


    filterDateChanged() {
      console.log("filterDateChanged: ");
    },


    confirmFilterDateChanged() {
      console.log("confirmFilterDateChanged: ");
      let options = {
        successCallback: () => {
          setTimeout(() => {
            this.computeIndividuals();
          }, 500);
        },
      };
      options.dateStart = this.currentFilterItem.start;
      options.dateEnd = this.currentFilterItem.end;
      this.$store.dispatch("fetchTracks", options);
    },




    /* --------------------------------------------------
              Panels show/hide toggling
    -------------------------------------------------- */

   hideAll(){
      this.mainMenuShown = false;
      this.howtouseShown = false;
      this.filtersShown = false;
      this.filterDetailsShown = false;
      this.trackDetailsShown = false;
      this.trackslistShown = false;
      this.draftTrackslistShown = false;
      this.aboutShown = false;
      this.hideAddTrackPanel(); // special case since it requires additional actions such as resetting form, resizing map...
      this.resizeMap();
   },
   
    showHowtouse() {
      this.sendGoogleAnalytics("screen-open", "faq");
      this.hideAll();
      this.howtouseShown = true;
    },
    goToBlog() {
      window.location.href = 'https://blog.projectyokkasoe.org/'+(this.$i18n.locale=='bu'?'my':'en')+'/blog/';
    },
    
    showAboutPanel() {
      this.sendGoogleAnalytics("screen-open", "about");
      this.hideAll();
      this.aboutShown = true;
    },

    showDraftTrackslist: function () {
      this.sendGoogleAnalytics("screen-open", "drafts-list");
      this.hideAll();
      this.hideAddTrackPanel();
      this.hideTrackDetails();
      this.draftTrackslistShown = true;
      this.setDraftMode(true);
      this.resizeMap();
    },

    hideDraftTrackslist: function () {
      this.draftTrackslistShown = false;
      this.setDraftMode(false);
      this.resizeMap();
    },

    hideFilters: function () {
      this.filtersShown = false;
      this.resizeMap();
    },

    toggleFilters: function () {
      this.sendGoogleAnalytics("screen-open", "filters");
      this.hideAll();
      this.hideTrackDetails();
      this.filtersShown = !this.filtersShown;
      this.resizeMap();
    },

    showTracksList: function () {
      this.sendGoogleAnalytics("screen-open", "tracks-list");
      this.setDraftMode(false);
      this.hideAll();
      this.trackslistShown = true;
      this.resizeMap();
    },

    hideTrackslist: function () {
      this.hideAll();
      this.resizeMap();
    },
    
    hideTrackDetails: function () {
      this.trackDetailsShown = false;
      this.resizeMap();
      this.map.dragPan.enable();
      this.map.scrollZoom.enable();
      this.selectedTrack = null;
    },

    showTrackDetails: function () {
      this.sendGoogleAnalytics("screen-open", "track-details");
      this.hideAll();
      this.trackDetailsShown = true;
      this.resizeMap();
      this.map.dragPan.disable();
      this.map.scrollZoom.disable();
    },

    showFilterDetails: function () {
      this.hideAll();
      this.hideTrackDetails();
      this.filterDetailsShown = true;
    },

    hideFilterDetails: function () {
      this.filterDetailsShown = false;
      this.filtersShown = true;
      this.resizeMap();
    },

    hideWelcome: function () {
      this.welcomeShown = false;
    },

    showAddTrackPanel: function (draft) {
      this.sendGoogleAnalytics("screen-open", "add-track");
      this.hideAll();
      this.hideTrackDetails();
      this.selectedTrack = null;
      this.addTrackPanelShown = true;
      this.$refs.addTrackPanel.openPanelHandler();

      this.map.setLayoutProperty("myLayerId", "visibility", "none");
      this.map.setLayoutProperty("myLayerId2", "visibility", "none");

      this.resizeMap();
      if (draft) {
        this.$refs.addTrackPanel.restoreDraft(draft);
      } else {
        this.getUserGeolocation();
      }
    },

    hideAboutPanel() {
      this.aboutShown = false;
    },

    showEnlargedImage() {
      this.enlargedPhotoShown = true;
    },
    
    showWelcomeScreen() {
      this.hideFilters();
      this.hideAddTrackPanel();
      this.hideTrackslist();
      this.hideDraftTrackslist();
      this.hideHowtouse();
      this.hideAboutPanel();
      this.welcomeShown = true;
    },

    toggleMainMenu() {
      this.mainMenuShown = !this.mainMenuShown;
      this.resizeMap();
    },


    hideAddTrackPanel() {
      this.addTrackPanelShown = false;
      this.map.dragPan.enable();
      this.map.scrollZoom.enable();
      this.$refs.addTrackPanel.resetForm();
      this.map.setLayoutProperty("myLayerId", "visibility", "visible");
      this.map.setLayoutProperty("myLayerId2", "visibility", "visible");
      this.resizeMap();
    },
    
  },

  
  created() {
    this.mapbox = Mapbox;
  },

  mounted() {
    global.vueApp = this;
    console.log("map init");
    this.$store.dispatch("fetchStats", {});
    this.$store.dispatch("fetchTracks", {
      successCallback: () => {
        setTimeout(() => {
          this.computeIndividuals();
        }, 500);
      },
    });
    this.$store.dispatch("fetchCategories", { level: 0, parentId: null });
  },
};
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
}

.blogContainer{
    display: flex;
    background-color: #fff6f4;
    position: relative;
    justify-items: center;
    flex-direction: column;
    padding: 100px 20px 30px;
    align-items: center;
}
.v-application a {
  color: #e86e59 !important;
  text-decoration: none;
}

.hidden {
  display: none;
}


.reducedPanel{
  /* transform: scale(0.9);
  transform-origin: top left;
  left: 20px !important;
  top: 20px !important; */
}

.reducedPanelContents{
  /* height: auto; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #423735;
  font-family: "Ubuntu Mono";
}



#viewContainer {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  height: calc(100% - 50px);
}

.defaultButton {
  border-radius: 0px;
  background-color: #e86e59;
  font-family: "Odibee Sans";
  font-size: 20px;
  color: #fffcf9;
  padding: 20px;
  border: none;
  width: 100%;
}

.panelTitle {
  font-family: "Odibee Sans";
  font-size: 24px;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  margin-top: 5px;
}

.panelTitleBurmese {
  font-size: 20px;
}

.panelTitle > .buttons {
  text-align: right;
}

.panelTitle > .buttons > img {
  height: 33px;
  margin-left: 10px;
}

.panelContents {
  height: 95%;
  overflow: auto;
  position: relative;
  margin-top: 0px;
}

.panelSubContents {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.secondPanel {
  background-color: #fffcf9;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 50px;
  height: 50vh;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
}



.listItemBurmese {
  padding: 15px 40px 15px 10px;
  font-size: 14px;
}

.listItem {
  border: solid 1px #ceb6b2;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0px;
  cursor: pointer;
  position: relative;
}

.listItem > img {
  float: left;
  margin-top: -8px;
}

.listItem > p {
  margin: 0px 0px 0px 40px;
}

.listItem:hover {
  background-color: #f1d9d4;
}

.tracklistItem {
  border: solid 1px #ceb6b2;
  border-radius: 10px;
  padding: 0px;
  margin: 10px 10px;
  overflow: hidden;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  line-height: 1em;
}

.tracklistItemTitle {
  line-height: 1.5em;
}

.tracklistItem:hover {
  box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 30%);
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-right {
  z-index: 0;
}

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out {
  display: none !important;
}

.trackTitle {
  font-family: "Ubuntu Mono", sans-serif;
  font-size: 20px;
  color: #e86e59;
}



.trackDate {
  font-family: "Ubuntu Mono";
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
  color: #423735;
}

.trackBreadcrumbs {
  font-family: "Ubuntu";
  font-size: 16px;
  line-height: 24px;
  color: #423735;
}

.trackDetailsSectionTitle {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #e86e59;
}
.trackDetailsSectionContent {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #423735;
  overflow-wrap: anywhere;
}

.trackDetailsChart {
  height: 8px;
  background: linear-gradient(90deg, #e86e59 0%, #6fcf97 100%);
  border-radius: 8px;
  margin-bottom: -20px;
  margin-top: -8px;
  margin-bottom: 5px;
}

.scaleTicks {
  display: flex;
  justify-content: center;
}

.scaleTicksMarks {
  flex-grow: 1;
  border-left: solid 1px #ceb6b2;
  height: 10px;
}

.scaleLegend {
  display: flex;
  justify-content: center;
  color: #423735;
  font-size: 14px;
}

.scaleLegendItem {
  flex-grow: 1;
}

.sectionDivider {
  border-bottom: 1px solid #ceb6b2;
  margin: 25px 0px;
}




.trackDetails > img {
  position: relative;
  width: 100%;
}


#map {
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
}



.mapboxgl-user-location-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-image: url("./assets/img/customicons/position_icon.png");
  background-color: unset;
  background-color: unset;
  box-shadow: none;
  background-size: 100%;
}
.mapboxgl-user-location-dot:before {
  content: none;
}
.mapboxgl-user-location-dot:after {
  content: none;
}

.mapboxgl-user-location-accuracy-circle {
  background-color: rgba(242, 114, 29, 0.2);
  width: 1px;
  height: 1px;
  border-radius: 100%;
  display: none;
}

.defaultInput {
  border: solid 1px #ceb6b2;
  border-radius: 10px;
  padding: 15px 10px;
  font-family: "Ubuntu";
  color: #423735;
  font-size: 16px;
  margin: 10px 0px;
  width: 100%;
  box-sizing: border-box;
}








.typeBullet {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: red;
  margin-right: 7px;
}
.customMarker {
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 50%;
  border: solid 4px #ff9900;
  background-size: cover;
  opacity: 1;
}

i.breadcrumbDivider {
  font-size: 17px !important;
  margin: 0px 5px !important;
  color: #ceb6b2 !important;
}

.markerSpike {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 41px;
  left: 17px;
  transform: rotateZ(135deg);
}

.fullPanel {
  bottom: 50px;
  height: calc(100% - 100px);
}

.listItem > img.threeDotsIcon {
  position: absolute;
  right: 10px;
  width: 40px;
  height: 40px;
}

.secondaryButton {
  border: solid 1px #e86e59;
  border-radius: 5px;
  color: #e86e59;
  width: fit-content;
  padding: 5px 15px 5px 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.orangeButton {
  background-color: #ffc352;
  width: 90px;
  padding: 10px 10px 3px;
  text-align: center;
  float: right;
}

.orangeButton > img {
  width: 32px;
}

.mgl-map-wrapper.reducedMap {
  height: calc(50vh - 90px);
}

.trackPhoto {
  width: 125px;
  height: 90px;
  background-color: #e7e7e7;
  background-size: cover;
}

.enlargedPhoto {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

.trackDetailsPhotoThumb {
  width: 100%;
  height: 150px;
  background-color: #e7e7e7;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 60px;
}



.addTrackTarget {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  margin-top: -15px;
  margin-left: -15px;
  pointer-events: none;
}

.mapResetFilterBtn {
  position: absolute;
  right: 5px;
  top: 50px;
  width: 38px;
}

/* transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: bottom 0.5s ease-in-out;
}
.slideUp-enter, .slideUp-leave-to /* .fade-leave-active below version 2.1.8 */ {
  bottom: -1000px;
}

.slideDown-enter-active,
.slideDown-leave-active {
  transition: top 0.5s ease-in-out;
}
.slideDown-enter, .slideDown-leave-to /* .fade-leave-active below version 2.1.8 */ {
  top: -500px !important;
}






#trackDetails {
  height: calc(50vh + 50px);
  bottom: 0px;
}

.homeFaqSection {
  display: none;
}

.filterDesktopButton {
  display: none;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1000ms infinite;
}

/* desktop */

@media only screen and (min-width: 416px) {
  .homeTopMenu {
    position: absolute;
    top: 25px;
    right: 130px;
    font-family: "Odibee Sans";
    font-size: 18px;
    text-shadow: 0px 0px 2px #ffe5b4;
  }

  .homeTopMenuButton:hover {
    color: #bb523e;
  }

  .homeTopMenuButton {
    color: #e86e59;
    display: inline-block;
    margin-left: 30px;
    cursor: pointer;
  }

  .mapboxgl-ctrl-zoom-in,
  .mapboxgl-ctrl-zoom-out {
    display: block !important;
  }

  .slideUp-enter-active,
  .slideUp-leave-active {
    transition: unset;
  }
  .slideUp-enter, .slideUp-leave-to /* .fade-leave-active below version 2.1.8 */ {
    bottom: -1000px;
  }

  .slideDown-enter-active,
  .slideDown-leave-active {
    transition: unset;
  }
  .slideDown-enter, .slideDown-leave-to /* .fade-leave-active below version 2.1.8 */ {
    top: -1000px !important;
  }

  .mapResetFilterBtn {
    top: 120px;
  }

  #trackDetails {
    height: 500px;
  }


  #viewContainer {
    position: relative;
    height: 700px;
  }



  .filterDesktopButton {
    display: block;
    background-color: #ffffff;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: -20px;
    left: 0px;
    border-radius: 0px 0px 15px 15px;
    text-align: center;
    padding-top: 10px;
    color: #e86e59;
    font-family: "Odibee Sans";
    font-size: 18px;
    cursor: pointer;
  }

  .filterDesktopButtonActive {
    background-color: #ffc352;
  }

  .homeSectionTitle {
    font-family: "Odibee Sans";
    font-size: 40px;
    color: #423735;
  }
  .homeSectionSubtitle {
    font-family: "Ubuntu Mono";
    font-size: 20px;
    color: #000000;
  }



  .numberedBullet {
    background: #e86e59;
    border-radius: 50%;
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    padding-top: 1px;
    font-family: "Odibee Sans";
    font-size: 24px;
    color: #ffffff;
  }
  .numberedBulletText {
    display: inline-block;
    width: 80%;
    padding-left: 10px;
  }

  .involvedContents {
    /* display: flex;
    flex-direction: revert; */
    text-align: left;
    width: 700px;
    margin-top: 40px;
  }

  .involvedSteps {
    /* margin: 50px 0px 0px -30px; */
    margin: 50px 100px 0px 100px;
    font-family: "Ubuntu";
    font-size: 16px;
  }

  .involvedSteps p {
    margin-bottom: 30px;
  }




  .aboutContents {
    display: flex;
    flex-direction: revert;
    text-align: left;
    width: 80%;
    margin-top: 40px;
  }

  .homeFaqSection {
    display: flex;
    background-color: #ffffff;
    position: relative;
    justify-items: center;
    flex-direction: column;
    padding: 0px 20px;
    align-items: center;
  }

  .homeFaqContents {
    text-align: left;
    width: 80%;
    margin-top: 40px;
    align-items: center;
    max-width: 900px;
  }



  .faqButton {
    font-family: "Odibee Sans";
    font-size: 24px;
    color: #000000;
    background-color: #e86e59;
    width: 300px !important;
    text-align: center;
    padding: 10px;
    margin-right: 20px;
  }

  #mainPanel {
    height: min-content;
    position: absolute;
    width: 350px;
    top: 80px;
    left: 50px;
    box-sizing: border-box;
    background-color: #fffcf9;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #888888;
  }

  .secondPanel {
    position: relative;
    bottom: unset;
    margin-bottom: 20px;
    height: 490px;
  }

  .mgl-map-wrapper.reducedMap {
    height: 100%;
  }

  .panelContents {
    height: calc(100% - 60px);
  }
}

@media only screen and (min-width: 716px) {
  .aboutSubtitle {
    width: 150%;
  }
}

</style>
