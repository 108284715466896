import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
import axios from 'axios'
const BASE_API_URL = 'https://backend.projectyokkasoe.org';
//const BASE_API_URL = 'http://192.168.0.15:8010/proxy'; // Using local CORS Proxy from here: https://www.npmjs.com/package/local-cors-proxy

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    publicPath: process.env.BASE_URL,
    loadingStatus: 'notLoading',
    stats: { total_records: 0, mean_scale: 0, total_area: 0 },
    tracks: [],
    regions: [],
    draftTracks: localStorage.draftTracks ? JSON.parse(localStorage.draftTracks) : [],
    categoriesFull: [],
    categories: [],
    categories2: [], 
    categories3: [], 
    categories4: [], 
    categories5: [], 
    categories6: [], 
    categoryColors: {
      1: { id:1, name_bu:"တောရိုင်းတိရိစ္ဆာန်", name: "Wildlife", color: '#F2C94C' }, 
      2: { id:2, name_bu:"သစ်တော", name: "Forest", color: '#27AE60' }, 
      3: { id:3, name_bu:"ရေ", name: "Water", color: '#56CCF2' }, 
      4: { id:4, name_bu:"သတ္တုတူးဖေါ်ခြင်း", name: "Mining", color: '#9B51E0' }, 
      5: { id:5, name_bu:"လူမှုအသိုက်အဝန်း", name: "Community", color: '#2F80ED' }, 
      6: { id:6, name_bu:"အခြေခံအဆောက်အအုံ", name: "Infrastructure", color: '#F2994A' }, 
      151: { id:151, name_bu:"xxxxxxxx", name: "Lives in immediate danger", color: '#CEB6B2' }, 
      159: { id:159, name_bu:"xxxxxxxx", name: "Land grabbing", color: '#CEB6B2' }, 
      160: { id:160, name_bu:"xxxxxxxx", name: "Ternure/security", color: '#CEB6B2' }
    }, 
    impacts:[
      { id: 1, name: i18n.t('impacts.label0')},
      { id: 2, name: i18n.t('impacts.label1')},
      { id: 3, name: i18n.t('impacts.label2')},
      { id: 4, name: i18n.t('impacts.label3')},
      { id: 5, name: i18n.t('impacts.label4') }
    ],
  }, 
  getters: {
    getCategoryColor: (state) => (id) => {
      if( state.categoryColors[id] )
        return state.categoryColors[id].color;
      else
        return "#CEB6B2";

    }
  },
  mutations: {
    SET_LOADING_STATUS(state, status){
      state.loadingStatus = status;
    }, 
    SET_REGIONS(state, regions){
      state.regions = regions;
    }, 
    SET_TRACKS(state, tracks){
      state.tracks = tracks;
    }, 
    SET_STATS(state, stats){
      state.stats = stats;
    }, 
    SET_CATEGORIES(state, categories){
      state.categories = categories;
    }, 
    SET_CATEGORIES2(state, categories){
      state.categories2 = categories;
    }, 
    SET_CATEGORIES3(state, categories){
      state.categories3 = categories;
    }, 
    SET_CATEGORIES4(state, categories){
      state.categories4 = categories;
    }, 
    SET_CATEGORIES5(state, categories){
      state.categories5 = categories;
    }, 
    SET_CATEGORIES6(state, categories){
      state.categories6 = categories;
    }, 
  },
  actions: {

    fetchTrackDetails(context, options){  //level, parentId
      console.log( "fetchTrackDetails: ", options.id );
      context.commit( 'SET_LOADING_STATUS', 'loading');
      let path = '/api/tracks/'+options.id;
      axios.get( BASE_API_URL + path).then(response=>{
      //axios.get( 'https://mocki.io/v1/d271b22b-c87e-4b44-bb47-23a517f328bc').then(response=>{
      options.callbackFunction( response.data );
      context.commit( 'SET_LOADING_STATUS', 'notLoading');      
      /* const cleanTracks = response.data.results.filter( (item) =>{
          return item.category_detail;
        } )  
        context.commit( 'SET_TRACKS', cleanTracks)*/
      })
    },

    fetchRegions(context, options){  //level, parentId
      console.log( "fetchRegions: " );
      context.commit( 'SET_LOADING_STATUS', 'loading');
      const path = '/api/regions';      
      axios.get( BASE_API_URL + path).then(response=>{        
        context.commit( 'SET_LOADING_STATUS', 'notLoading');        
        context.commit( 'SET_REGIONS', response.data.results);
        if( options.successCallback )
          options.successCallback(  );        
      })
    },

    fetchStats(context, options){  //level, parentId
      console.log( "fetchStats: " );
      context.commit( 'SET_LOADING_STATUS', 'loading');
      const path = '/api/stats';      
      axios.get( BASE_API_URL + path).then(response=>{        
        context.commit( 'SET_LOADING_STATUS', 'notLoading');        
        context.commit( 'SET_STATS', response.data);
        if( options.successCallback )
          options.successCallback(  );        
      })
    },

    fetchTracks(context, options){  //level, parentId
      console.log( "fetchTracks: " );
      context.commit( 'SET_LOADING_STATUS', 'loading');
      let path = '/api/tracks';
      if( options.category )
        path += "?category="+options.category;
      else if( options.scale )
        path += "?scale="+options.scale;
      else if( options.dateStart && options.dateEnd ){
        path += "?dateStart="+encodeURI(options.dateStart)+"&dateEnd="+encodeURI(options.dateEnd);
      }
      axios.get( BASE_API_URL + path).then(response=>{
      //axios.get( 'https://mocki.io/v1/d271b22b-c87e-4b44-bb47-23a517f328bc').then(response=>{
        const cleanTracks = response.data.results.filter( (item) =>{
          return item.category_detail && item.geo_location;
        } );
        const sortedTracks = cleanTracks.sort( (itemA, itemB ) =>{
          if (itemA.created_date < itemB.created_date )
            return 1;
          else 
            return -1;
        } );
        
        context.commit( 'SET_LOADING_STATUS', 'notLoading');        
        context.commit( 'SET_TRACKS', sortedTracks);
        if( options.successCallback )
          options.successCallback(  );
        
      })
    },

    fetchCategories(context, options){  //level, parentId
      console.log( "fetchCategories: ", options.level, options.parentId );
      context.commit( 'SET_LOADING_STATUS', 'loading');
      if( context.state.categoriesFull && options.parentId ){
        const parent = context.state.categoriesFull.filter( (category)=>{
          return category.id == options.parentId;
        } );
        context.commit( 'SET_LOADING_STATUS', 'notLoading')
        let mutationName = 'SET_CATEGORIES';
        if( options.level ) mutationName += options.level;
        context.commit( mutationName, parent[0].children);
      }else{
        const extractChildren = ( parent ) => {
          context.state.categoriesFull.push( parent );
          parent.children.forEach(child => {
            extractChildren( child );
          });
        }
        let path = '/api/categoriesfull';
        if( options.parentId ) 
        path += '/' + options.parentId;
        axios.get( BASE_API_URL + path).then(response=>{
        //axios.get( 'https://mocki.io/v1/d271b22b-c87e-4b44-bb47-23a517f328bc').then(response=>{
          context.commit( 'SET_LOADING_STATUS', 'notLoading')
          let mutationName = 'SET_CATEGORIES';
          if( options.level ) mutationName += options.level;
          context.commit( mutationName, response.data);
          response.data.forEach(category => {
            extractChildren(category);
          });
        })
      }
    },

    saveTrackData(context, options ){
      console.log( "saveTrackData(  ) ", context, options.formData );
      context.commit( 'SET_LOADING_STATUS', 'loading');

      const path = '/api/tracks';
      axios.post( BASE_API_URL + path, 
        options.formData, 
      )
      .then(response=>{
        console.log( "[Vuex.Store] Track saved succcessfully: ", response.data );
        if( options.successCallback )
          options.successCallback( response.data );
        })
      .catch(function (error) {
        console.log( "[Vuex.Store] ERROR Saving: ", error.toJSON());
        if( options.errorCallback )
          options.errorCallback( error );
      });
    }, 
    uploadTrackPhoto( context, options ){
      var formData = new FormData();
      formData.append("photo", options.photoFile, options.photoFile.name);
      const path = '/api/tracks/'+options.id;
      axios.put( BASE_API_URL + path, formData, {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
      })
      .then(response=>{
        console.log( "[Vuex.Store] Photo saved succcessfully: ", response );
        if( options.successCallback )
          options.successCallback( response );
        })
      .catch(function (error) {
        console.log( "[Vuex.Store] ERROR uploading photo: ", error.toJSON());
        if( options.errorCallback )
          options.errorCallback( error );
      });
    }, 

    saveTrackAsDraft( context, options ){
      const draftIndex = options.trackData.draftIndex;
      if( draftIndex !== null )
        context.state.draftTracks[draftIndex] = options.trackData;
      else
        context.state.draftTracks.push( options.trackData );      
      let draftsString = JSON.stringify( context.state.draftTracks );
      localStorage.setItem( 'draftTracks', draftsString );
    }, 

    deleteDraftTrack( context, options ){
      context.state.draftTracks.splice( options.draftIndex, 1 );
      let draftsString = JSON.stringify( context.state.draftTracks );
      localStorage.setItem( 'draftTracks', draftsString );
    }

    }
})
