<template>
    <div>        
        <img  class="contactIllustration" src="./assets/img/tapir.png" style="position: absolute; width: 200px; top: -70px; left: 90px; z-index: 1;">
        <div style="display: flex; justify-content: center;">
            <div style="width: 1050px; margin: 15px; text-align: left;">
                <div style="font-family: 'Odibee Sans'; font-size: 40px; text-transform: uppercase; margin: 20px 0px;">{{ $t('impactPage.recent-news') }}</div>
            </div>
            <!-- <div style="width: 333px; margin: 15px;"></div>
            <div style="width: 333px; margin: 15px;"></div>   -->    
        </div>
        <div class="blogItemsContainer" style="">
            <div class="blogItem" v-for="post in posts" @click="gotoPost(post.url)">
                <div class="blogPhoto" :style="{ 'background-image': 'url('+post.thumbnail+')'}"></div>
                <div class="blogTitle">
                    <span style="font-size: 16px; color: #423735;">{{getPostDate(post)}}</span><br/>
                    {{post.title}}</div>
            </div>        
        </div>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            posts: []
        }
    },
    methods: {
        gotoPost( url ){
            window.open( url );
        }, 
        getPostDate(post){
            let parts = post.photo.split("/");
            let date = parts[4] + "/" + parts[5] + "/" + parts[6];
            return date;
        }
    }, 
    mounted(){
        axios
        .get("https://backend.projectyokkasoe.org/en/blog/api/latestposts?limit=3")
        .then(result =>{
            console.log("loaded posts stats: ", result.data.results);
            this.posts = result.data.results;
        })
    }
}
</script>

<style scoped>
.blogItem{
    margin: 15px;
    cursor: pointer;
}
.blogPhoto{
    width: 333px;
    height: 273px;
    background-image: url(https://projectyokkasoe-uploads.s3.amazonaws.com/thumbnails/blog/2022/03/26/G5HazQKK4SLrk4y54ikRjq_medium.jpg);
    background-size: cover;
    background-position: center;
}
.blogTitle{
    width: 333px;
    height: 168px;
    background-color: #E86E59;
    padding: 20px;
    color: #ffffff;
    box-sizing: border-box;
    font-size: 24px;
    font-family: Ubuntu Mono;
    text-transform: uppercase;
    text-align: left;
    line-height: 22px;
}
.blogItemsContainer{
    display: flex; 
    justify-content: center;
}


@media only screen and (max-width: 425px) {
    .contactIllustration{
        display: none;
    }

    .blogPhoto, .blogTitle{
        width: unset;
    }
    
    .blogItemsContainer{
        display: unset; 
        justify-content: center;
    }
}
</style>