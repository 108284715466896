<template>
    <div class="faqItem">
        <div :class="{ question: true, questionBurmese:$i18n.locale=='bu'}" @click="toggleVisible">{{faqObject.question}}</div>
        <transition name="fade">
            <div class="answer" v-if="expanded" v-html="faqObject.answer"></div>
        </transition>
    </div>
</template>




<script>
export default {
    props: {
        faqObject: Object,        
    },
    data(){
        return {
            expanded: false
        }
    },
    methods: {
        toggleVisible(){
            this.expanded = !this.expanded;
        }
    }
}
</script>



<style scoped>
.faqItem{
    width: 100%;
    padding: 50px 0px;
    border-bottom: solid 1px #CEB6B2;
}


.question{
    color: #E86E59;
    font-family: 'Ubuntu Mono';
    font-size: 20px;
    cursor: pointer;
}

.questionBurmese{
    line-height: 36px;
}

.question:hover{
    text-decoration: underline;
}
.answer{
    color: #000000;
    font-family: 'Ubuntu';
    font-size: 16px;
    margin-top: 30px;
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  all: 0;
}
</style>