<template>
    <div id="bottombarDesktop" v-show="!trackDetailsShown && !addTrackPanelShown">
        <div class="bottombarDesktop-button" style="border-right: solid 1px #e86e59; border-radius: 0px 0px 0px 15px;" @click="toggleFilters">
        <img style="width:32px" src="../assets/img/customicons/funnel_off.png" v-if="!$parent.$parent.currentFilterItem"/>
        <img style="width:32px" src="../assets/img/customicons/funnel_on.png" v-if="$parent.$parent.currentFilterItem"/>
        <div class="buttonText" v-if="!$parent.$parent.currentFilterItem">{{
            $t("tracksList.filter-map-results")
            }}</div>
        <div class="buttonText" v-if="$parent.$parent.currentFilterItem">{{
            $t("tracksList.change-filter")
            }}</div>
        </div>
        
        <div class="bottombarDesktop-button" style="border-radius: 0px 0px 15px 0px; background-color: rgb(232, 110, 89);" @click="showAddTrackPanel()">
        <img :src="`${publicPath}img/customicons/Plus_inv.png`" />
        <div class="buttonText" style="top: -11px; color: #ffc352;">{{
            $t("addRecord.add-a-record")
            }}</div>
        </div>
        </div>
        
        
        <!-- <div class="bottombarDesktop-button" style="position: relative; top: 8px;" @click="showTracksList">
            <img :src="`${publicPath}img/customicons/ListDashes.png`" />
        </div> -->
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    currentFilterItem: Object,
    trackDetailsShown: Boolean,
    addTrackPanelShown: Boolean,
    toggleFilters: Function,
    showAddTrackPanel: Function,
    showTracksList: Function
  },
  components: {},
  computed: {
    ...mapState(["publicPath"]),
  },
};
</script>

<style scoped>

.buttonText{
    font-size: 0.9em;
    max-width: 100px;
    display: inline-block;
    position: relative;
    /* background-color: #ff0000; */
    line-height: 13px;
    text-align: left;
    top: -5px;
    margin-left: 5px;
    color: #cd614f;
}

#bottombarDesktop {
  display: none;
}

.bottombarDesktop-button {
    text-align: center;
    padding-top: 8px;
    top: 0px;
    cursor: pointer;
}
.bottombarDesktop-button:hover {
    background-color: #efb855;
}

/* desktop */
@media only screen and (min-width: 416px) {
  #bottombarDesktop {
    /* display: none; */
    background-color: #ffc352;
    position: absolute;
    bottom: -20px;
    height: 50px;
    left: 0px;
    right: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 0px 0px 15px 15px;
  }
}
</style>