<template>    
    <header class="container  page-header">
      <div class="page-header__logo">
        <div class="logo">
            <img id="logo" src="./assets/logo.png" style="cursor: pointer;">
        </div>
      </div>
      <nav class="main-nav" ref="mainNav">
        <ul class="main-nav__items">
          <li class="main-nav__item">
            <a href="/#viewContainer">{{ $t('menu.map') }}</a>

          </li>
          <li class="main-nav__item">
            <a href="/#homeAbout">{{ $t('menu.about') }}</a>

          </li>
          <li class="main-nav__item">
            <a href="/#homeMainContents">{{ $t('menu.get-involved') }}</a>
          </li>
          <li class="main-nav__item">
            <a href="/#faqSection">{{ $t('menu.faq') }}</a>
          </li>

          <!-- <li class="main-nav__item">
            <a class="" :href="'https://blog.projectyokkasoe.org/'+($i18n.locale=='bu'?'my':'en')+'/blog/'">{{ $t('menu.blog') }}</a>
          </li> -->

          <li class="main-nav__item" style="">            
            <a v-if="$i18n.locale=='bu'" class="" @click="changeLanguage( 'en' )">English</a>
            <a v-if="$i18n.locale!='bu'" class="" @click="changeLanguage( 'bu' )">မြန်မာ</a>            
          </li>
        </ul>
      </nav>
      <div class="nav-toggle" @click="toggleMenu">
        <div class="nav-toggle__icon">
          <img id="burger" src="./assets/img/burger.png">
        </div>
      </div>
    </header>
</template>

<script>
export default {
  methods:{
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    toggleMenu(){
      let display = this.$refs.mainNav.style["display"];
      if( display == "block" )
        this.$refs.mainNav.style["display"] = "";
      else
        this.$refs.mainNav.style["display"] = "block";
      //console.log( "mainNav: ", this.$refs.mainNav );
    }
  }
}
</script>

<style scoped>
.page-header {
  font-family:'Odibee Sans', sans-serif;
  font-size: 18px;
  margin-top: 0;
  padding-top: 5px;
  border: none;
  background-color: #e86e59;
  width: 100%;
  padding: 0px 105px 0px;
}
.page-header__logo {
  text-align: center;
  float: left;
}
 
.page-header {
  display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
  align-items: center;
  z-index: 1000000;
}
@media (max-width: 992px) {
  .page-header {
    padding: 0;
    height: 50px;
    margin: 0;
    overflow: hidden;
  }
}

/*------------------------------------------------------------------
[3. Logo ] 
*/
.logo h1 {
  margin-top: 0;
}
.logo__link {
  font-size: 48px;
  color: #262626;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.logo__link:hover {
  color: #262626;
  text-decoration: none;
  opacity: 0.8;
}
.logo__description {
  font-size: 14px;
}
@media (max-width: 992px) {
  .logo #logo {
    height: 50px;
  }
}
/*------------------------------------------------------------------
[4. Navigation ] 
*/
.main-nav {
  /* position: relative;
  overflow: hidden;
  margin-left: auto; */
  position: fixed;
    overflow: hidden;
    right: 10px;
      top: 25px;
}
.main-nav__items {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.main-nav__items-submenu {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 30px;
  left: -50px;
  min-width: 245px;
  padding: 20px 0;
  padding-left: 50px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  background-color: #dcdcdc;
  border: 1px solid #dcdcdc;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all .3s ease .15s;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.main-nav__item {
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
}
.main-nav__item--active {
  font-weight: bold;
}
.main-nav__item--active::after {
  font-weight: normal;
}
header nav ul {
  list-style: none;
  float: right;
}

header nav ul li {
  float: left;
}

.main-nav__item a {
  text-decoration: none;
  display: inline-block;
  padding: 15px 20px;
  color: rgb(242, 201, 76) !important;
}

.main-nav__item a:hover {
  color: #f90 !important;
}

.main-nav__item a:vsited{
  background: #f90 !important;
}

.main-nav__item:hover .main-nav__items-submenu {
  opacity: 1;
  visibility: visible;
}

.main-nav__item a {
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.main-nav__item a:hover {
  opacity: 0.8;
}
.main-nav__item-submenu {
  padding: 5px 0;
}
.main-nav__item-submenu--active {
  font-weight: bold;
}
.main-nav__search {
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
}
.main-nav__search svg {
  width: 24px;
  height: 24px;
}
.main-nav__search:hover svg {
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .main-nav {
    position: fixed;
    left: 0;
    display: none;
    /*transition: all .5s ease-in-out;*/
    height: 450px;
    top: 50px;
    background-color: #fffcf9;
    z-index: 1;
    padding: 20px 20px 20px 10px;
    padding-right: 20px;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .main-nav--mobile {
    /*position: fixed;*/
    /*top: 50px;*/

    /*opacity: 1;
    visibility: visible;
    z-index: 1;*/
    

  }
  .main-nav__items {
    float: none;
  }
  .main-nav__item {
    font-family: 'Ubuntu Mono', sans-serif;
    background: #fffcf9;
    display: block;
    font-size: 16px;
    margin: 5px;
    padding: 0px 0px;
    text-align: left;
    width: 100%;
    border: 2px solid #e86e59;
    border-radius: 10px;
    text-decoration: none;
    box-shadow: 0px 3px 0px #e86e59;
  }
  .main-nav__item a {
    width: 100%;
    color: #e86e59 !important;
  }
  .main-nav__search {
    right: 0;
  }
  .main-nav--mobile .main-nav__items .main-nav__items-submenu {
    position: relative;
    top: 0;
    left: -25px;
    min-width: 235px;
    padding-top: 10px;
    padding-bottom: 0;
    visibility: visible;
    opacity: 1;
  }
  .main-nav--mobile .main-nav__item {
    display: block;
  }
  .main-nav--mobile .main-nav__item::after {
    content: "";
    margin-left: 0;
  }
  .main-nav--mobile .main-nav__search {
    position: relative;
  }
}
/*------------------------------------------------------------------
[5. Navigation Toggle ] 
*/
.nav-toggle {
  display: none;
}
@media (max-width: 992px) {
  .nav-toggle {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: 15px;
  }
  .nav-toggle__icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
  }
  .nav-toggle__icon #burger {
    height: 20px;
  }
  .nav-toggle__icon:hover {
    opacity: 0.8;
  }
  .nav-toggle__icon:focus {
    outline: 0;
  }
  .nav-toggle__icon:active {
    box-shadow: none;
  }
}
</style>